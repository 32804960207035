import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5d610792 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5c3d11c9 = () => interopDefault(import('../pages/account/index/add.vue' /* webpackChunkName: "pages/account/index/add" */))
const _37b39352 = () => interopDefault(import('../pages/account/index/_user/edit.vue' /* webpackChunkName: "pages/account/index/_user/edit" */))
const _00ea7299 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _8e06d18e = () => interopDefault(import('../pages/admin/index/channels/index.vue' /* webpackChunkName: "pages/admin/index/channels/index" */))
const _eb53893c = () => interopDefault(import('../pages/admin/index/collections/index.vue' /* webpackChunkName: "pages/admin/index/collections/index" */))
const _83faabc4 = () => interopDefault(import('../pages/admin/index/dashboard.vue' /* webpackChunkName: "pages/admin/index/dashboard" */))
const _eaf27c3c = () => interopDefault(import('../pages/admin/index/feeds/index.vue' /* webpackChunkName: "pages/admin/index/feeds/index" */))
const _52fd5886 = () => interopDefault(import('../pages/admin/index/groups/index.vue' /* webpackChunkName: "pages/admin/index/groups/index" */))
const _2f848809 = () => interopDefault(import('../pages/admin/index/settings.vue' /* webpackChunkName: "pages/admin/index/settings" */))
const _d78be4d6 = () => interopDefault(import('../pages/admin/index/users/index.vue' /* webpackChunkName: "pages/admin/index/users/index" */))
const _ef656170 = () => interopDefault(import('../pages/admin/index/channels/add.vue' /* webpackChunkName: "pages/admin/index/channels/add" */))
const _24e3e9b1 = () => interopDefault(import('../pages/admin/index/collections/add.vue' /* webpackChunkName: "pages/admin/index/collections/add" */))
const _e6f7df9e = () => interopDefault(import('../pages/admin/index/feeds/add.vue' /* webpackChunkName: "pages/admin/index/feeds/add" */))
const _5bc14668 = () => interopDefault(import('../pages/admin/index/groups/add.vue' /* webpackChunkName: "pages/admin/index/groups/add" */))
const _4decaca4 = () => interopDefault(import('../pages/admin/index/users/add.vue' /* webpackChunkName: "pages/admin/index/users/add" */))
const _47cc45f2 = () => interopDefault(import('../pages/admin/index/users/import.vue' /* webpackChunkName: "pages/admin/index/users/import" */))
const _bf770948 = () => interopDefault(import('../pages/admin/index/channels/_channel/edit.vue' /* webpackChunkName: "pages/admin/index/channels/_channel/edit" */))
const _9bc60d88 = () => interopDefault(import('../pages/admin/index/collections/_collection/edit.vue' /* webpackChunkName: "pages/admin/index/collections/_collection/edit" */))
const _5491d308 = () => interopDefault(import('../pages/admin/index/feeds/_feed/edit.vue' /* webpackChunkName: "pages/admin/index/feeds/_feed/edit" */))
const _9d472b38 = () => interopDefault(import('../pages/admin/index/groups/_group/edit.vue' /* webpackChunkName: "pages/admin/index/groups/_group/edit" */))
const _5b9281c8 = () => interopDefault(import('../pages/admin/index/users/_user/edit.vue' /* webpackChunkName: "pages/admin/index/users/_user/edit" */))
const _04197b50 = () => interopDefault(import('../pages/admin/index/channels/_channel/_contribution/index.vue' /* webpackChunkName: "pages/admin/index/channels/_channel/_contribution/index" */))
const _7ff93c30 = () => interopDefault(import('../pages/admin/index/feeds/_feed/_contribution/index.vue' /* webpackChunkName: "pages/admin/index/feeds/_feed/_contribution/index" */))
const _695dfdfc = () => interopDefault(import('../pages/admin/index/channels/_channel/_contribution/edit.vue' /* webpackChunkName: "pages/admin/index/channels/_channel/_contribution/edit" */))
const _4412a11c = () => interopDefault(import('../pages/admin/index/feeds/_feed/_contribution/edit.vue' /* webpackChunkName: "pages/admin/index/feeds/_feed/_contribution/edit" */))
const _d1e364c8 = () => interopDefault(import('../pages/channels/index.vue' /* webpackChunkName: "pages/channels/index" */))
const _35bb94ca = () => interopDefault(import('../pages/channels/index/_channel/index.vue' /* webpackChunkName: "pages/channels/index/_channel/index" */))
const _04f4a8dc = () => interopDefault(import('../pages/channels/index/_channel/index/add.vue' /* webpackChunkName: "pages/channels/index/_channel/index/add" */))
const _456719df = () => interopDefault(import('../pages/channels/index/_channel/index/edit.vue' /* webpackChunkName: "pages/channels/index/_channel/index/edit" */))
const _a968b326 = () => interopDefault(import('../pages/channels/index/_channel/index/_contribution/index.vue' /* webpackChunkName: "pages/channels/index/_channel/index/_contribution/index" */))
const _5c490982 = () => interopDefault(import('../pages/channels/index/_channel/index/_contribution/edit.vue' /* webpackChunkName: "pages/channels/index/_channel/index/_contribution/edit" */))
const _35e7528f = () => interopDefault(import('../pages/channels copy/index.vue' /* webpackChunkName: "pages/channels copy/index" */))
const _a02aa93a = () => interopDefault(import('../pages/choose/index.vue' /* webpackChunkName: "pages/choose/index" */))
const _6fd68836 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _069c6a35 = () => interopDefault(import('../pages/events/index/_event.vue' /* webpackChunkName: "pages/events/index/_event" */))
const _68ef8fc6 = () => interopDefault(import('../pages/give/index.vue' /* webpackChunkName: "pages/give/index" */))
const _5d4346e0 = () => interopDefault(import('../pages/groups/index.vue' /* webpackChunkName: "pages/groups/index" */))
const _42ed401c = () => interopDefault(import('../pages/groups/index/add.vue' /* webpackChunkName: "pages/groups/index/add" */))
const _25f8c382 = () => interopDefault(import('../pages/groups/index/_group/index.vue' /* webpackChunkName: "pages/groups/index/_group/index" */))
const _0d7191a0 = () => interopDefault(import('../pages/groups/index/_group/index/channels/index.vue' /* webpackChunkName: "pages/groups/index/_group/index/channels/index" */))
const _47c7d0b6 = () => interopDefault(import('../pages/groups/index/_group/index/channels/index/_channel/index.vue' /* webpackChunkName: "pages/groups/index/_group/index/channels/index/_channel/index" */))
const _c8dd6e04 = () => interopDefault(import('../pages/groups/index/_group/index/users/index.vue' /* webpackChunkName: "pages/groups/index/_group/index/users/index" */))
const _3bb69901 = () => interopDefault(import('../pages/groups/index/_group/index/users/index/index.vue' /* webpackChunkName: "pages/groups/index/_group/index/users/index/index" */))
const _2422289c = () => interopDefault(import('../pages/groups/index/_group/index/users/index/_user/index.vue' /* webpackChunkName: "pages/groups/index/_group/index/users/index/_user/index" */))
const _f98830ec = () => interopDefault(import('../pages/groups/index/_group/edit.vue' /* webpackChunkName: "pages/groups/index/_group/edit" */))
const _018a93f4 = () => interopDefault(import('../pages/guide/index.vue' /* webpackChunkName: "pages/guide/index" */))
const _f6bda34e = () => interopDefault(import('../pages/guide/index/_guide/index.vue' /* webpackChunkName: "pages/guide/index/_guide/index" */))
const _7ce683b0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f006171c = () => interopDefault(import('../pages/pay/index.vue' /* webpackChunkName: "pages/pay/index" */))
const _06c5405c = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _6c43df61 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _08a8abdc = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _7ec8da24 = () => interopDefault(import('../pages/users/index/add.vue' /* webpackChunkName: "pages/users/index/add" */))
const _15c39d42 = () => interopDefault(import('../pages/users/index/map.vue' /* webpackChunkName: "pages/users/index/map" */))
const _74ef8bb0 = () => interopDefault(import('../pages/users/index/_user/index.vue' /* webpackChunkName: "pages/users/index/_user/index" */))
const _91573cc8 = () => interopDefault(import('../pages/users/index/_user/edit.vue' /* webpackChunkName: "pages/users/index/_user/edit" */))
const _0e36a21f = () => interopDefault(import('../pages/give/history.vue' /* webpackChunkName: "pages/give/history" */))
const _6ab3614c = () => interopDefault(import('../pages/channels copy/_channel/index.vue' /* webpackChunkName: "pages/channels copy/_channel/index" */))
const _203baaea = () => interopDefault(import('../pages/choose/_id.vue' /* webpackChunkName: "pages/choose/_id" */))
const _3f989ba9 = () => interopDefault(import('../pages/channels copy/_channel/add.vue' /* webpackChunkName: "pages/channels copy/_channel/add" */))
const _5f4180b2 = () => interopDefault(import('../pages/channels copy/_channel/edit.vue' /* webpackChunkName: "pages/channels copy/_channel/edit" */))
const _6bd25f3a = () => interopDefault(import('../pages/channels copy/_channel/_contribution/index.vue' /* webpackChunkName: "pages/channels copy/_channel/_contribution/index" */))
const _2263f4d2 = () => interopDefault(import('../pages/channels copy/_channel/_contribution/edit.vue' /* webpackChunkName: "pages/channels copy/_channel/_contribution/edit" */))
const _99823ece = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _5d610792,
    name: "account",
    children: [{
      path: "add",
      component: _5c3d11c9,
      name: "account-index-add"
    }, {
      path: ":user?/edit",
      component: _37b39352,
      name: "account-index-user-edit"
    }]
  }, {
    path: "/admin",
    component: _00ea7299,
    name: "admin",
    children: [{
      path: "channels",
      component: _8e06d18e,
      name: "admin-index-channels"
    }, {
      path: "collections",
      component: _eb53893c,
      name: "admin-index-collections"
    }, {
      path: "dashboard",
      component: _83faabc4,
      name: "admin-index-dashboard"
    }, {
      path: "feeds",
      component: _eaf27c3c,
      name: "admin-index-feeds"
    }, {
      path: "groups",
      component: _52fd5886,
      name: "admin-index-groups"
    }, {
      path: "settings",
      component: _2f848809,
      name: "admin-index-settings"
    }, {
      path: "users",
      component: _d78be4d6,
      name: "admin-index-users"
    }, {
      path: "channels/add",
      component: _ef656170,
      name: "admin-index-channels-add"
    }, {
      path: "collections/add",
      component: _24e3e9b1,
      name: "admin-index-collections-add"
    }, {
      path: "feeds/add",
      component: _e6f7df9e,
      name: "admin-index-feeds-add"
    }, {
      path: "groups/add",
      component: _5bc14668,
      name: "admin-index-groups-add"
    }, {
      path: "users/add",
      component: _4decaca4,
      name: "admin-index-users-add"
    }, {
      path: "users/import",
      component: _47cc45f2,
      name: "admin-index-users-import"
    }, {
      path: "channels/:channel?/edit",
      component: _bf770948,
      name: "admin-index-channels-channel-edit"
    }, {
      path: "collections/:collection?/edit",
      component: _9bc60d88,
      name: "admin-index-collections-collection-edit"
    }, {
      path: "feeds/:feed?/edit",
      component: _5491d308,
      name: "admin-index-feeds-feed-edit"
    }, {
      path: "groups/:group?/edit",
      component: _9d472b38,
      name: "admin-index-groups-group-edit"
    }, {
      path: "users/:user?/edit",
      component: _5b9281c8,
      name: "admin-index-users-user-edit"
    }, {
      path: "channels/:channel?/:contribution",
      component: _04197b50,
      name: "admin-index-channels-channel-contribution"
    }, {
      path: "feeds/:feed?/:contribution",
      component: _7ff93c30,
      name: "admin-index-feeds-feed-contribution"
    }, {
      path: "channels/:channel?/:contribution?/edit",
      component: _695dfdfc,
      name: "admin-index-channels-channel-contribution-edit"
    }, {
      path: "feeds/:feed?/:contribution?/edit",
      component: _4412a11c,
      name: "admin-index-feeds-feed-contribution-edit"
    }]
  }, {
    path: "/channels",
    component: _d1e364c8,
    name: "channels",
    children: [{
      path: ":channel",
      component: _35bb94ca,
      name: "channels-index-channel",
      children: [{
        path: "add",
        component: _04f4a8dc,
        name: "channels-index-channel-index-add"
      }, {
        path: "edit",
        component: _456719df,
        name: "channels-index-channel-index-edit"
      }, {
        path: ":contribution",
        component: _a968b326,
        name: "channels-index-channel-index-contribution"
      }, {
        path: ":contribution/edit",
        component: _5c490982,
        name: "channels-index-channel-index-contribution-edit"
      }]
    }]
  }, {
    path: "/channels%20copy",
    component: _35e7528f,
    name: "channels copy"
  }, {
    path: "/choose",
    component: _a02aa93a,
    name: "choose"
  }, {
    path: "/events",
    component: _6fd68836,
    name: "events",
    children: [{
      path: ":event?",
      component: _069c6a35,
      name: "events-index-event"
    }]
  }, {
    path: "/give",
    component: _68ef8fc6,
    name: "give"
  }, {
    path: "/groups",
    component: _5d4346e0,
    name: "groups",
    children: [{
      path: "add",
      component: _42ed401c,
      name: "groups-index-add"
    }, {
      path: ":group",
      component: _25f8c382,
      name: "groups-index-group",
      children: [{
        path: "channels",
        component: _0d7191a0,
        name: "groups-index-group-index-channels",
        children: [{
          path: ":channel",
          component: _47c7d0b6,
          name: "groups-index-group-index-channels-index-channel"
        }]
      }, {
        path: "users",
        component: _c8dd6e04,
        children: [{
          path: "",
          component: _3bb69901,
          name: "groups-index-group-index-users-index"
        }, {
          path: ":user",
          component: _2422289c,
          name: "groups-index-group-index-users-index-user"
        }]
      }]
    }, {
      path: ":group/edit",
      component: _f98830ec,
      name: "groups-index-group-edit"
    }]
  }, {
    path: "/guide",
    component: _018a93f4,
    name: "guide",
    children: [{
      path: ":guide",
      component: _f6bda34e,
      name: "guide-index-guide"
    }]
  }, {
    path: "/login",
    component: _7ce683b0,
    name: "login"
  }, {
    path: "/pay",
    component: _f006171c,
    name: "pay"
  }, {
    path: "/privacy",
    component: _06c5405c,
    name: "privacy"
  }, {
    path: "/signup",
    component: _6c43df61,
    name: "signup"
  }, {
    path: "/users",
    component: _08a8abdc,
    name: "users",
    children: [{
      path: "add",
      component: _7ec8da24,
      name: "users-index-add"
    }, {
      path: "map",
      component: _15c39d42,
      name: "users-index-map"
    }, {
      path: ":user",
      component: _74ef8bb0,
      name: "users-index-user"
    }, {
      path: ":user/edit",
      component: _91573cc8,
      name: "users-index-user-edit"
    }]
  }, {
    path: "/give/history",
    component: _0e36a21f,
    name: "give-history"
  }, {
    path: "/channels%20copy/:channel",
    component: _6ab3614c,
    name: "channels copy-channel"
  }, {
    path: "/choose/:id",
    component: _203baaea,
    name: "choose-id"
  }, {
    path: "/channels%20copy/:channel/add",
    component: _3f989ba9,
    name: "channels copy-channel-add"
  }, {
    path: "/channels%20copy/:channel/edit",
    component: _5f4180b2,
    name: "channels copy-channel-edit"
  }, {
    path: "/channels%20copy/:channel/:contribution",
    component: _6bd25f3a,
    name: "channels copy-channel-contribution"
  }, {
    path: "/channels%20copy/:channel/:contribution/edit",
    component: _2263f4d2,
    name: "channels copy-channel-contribution-edit"
  }, {
    path: "/",
    component: _99823ece,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
