<template>
	<span class="icon iconaria" :class="[newType, size]" aria-hidden="true">
		<i
			v-if="!useIconComponent"
			:class="[newPack, newIcon, newCustomSize, customClass]"
		/>

		<component
			v-else
			:is="useIconComponent"
			:icon="newIcon"
			:size="newCustomSize"
			:class="[customClass]"
		/>
	</span>
</template>

<script>
import config from "~/node_modules/buefy/src/utils/config";
import getIcons from "~/node_modules/buefy/src/utils/icons";

export default {
	name: "BIconaria",
	props: {
		type: [String, Object],
		component: String,
		pack: String,
		icon: [String, Array],
		size: String,
		customSize: String,
		customClass: String,
		both: Boolean, // This is used internally to show both MDI and FA icon
	},
	computed: {
		iconConfig() {
			let allIcons = getIcons();
			return allIcons[this.newPack];
		},
		iconPrefix() {
			if (this.iconConfig && this.iconConfig.iconPrefix) {
				return this.iconConfig.iconPrefix;
			}
			return "";
		},
		/**
		 * Internal icon name based on the pack.
		 * If pack is 'fa', gets the equivalent FA icon name of the MDI,
		 * internal icons are always MDI.
		 */
		newIcon() {
			return `${this.getEquivalentIconOf(this.icon)}`;
		},
		newPack() {
			return this.pack || config.defaultIconPack;
		},
		newType() {
			if (!this.type) return;

			let splitType = [];
			if (typeof this.type === "string") {
				splitType = this.type.split("-");
			} else {
				for (let key in this.type) {
					if (this.type[key]) {
						splitType = key.split("-");
						break;
					}
				}
			}
			if (splitType.length <= 1) return;

			const [, ...type] = splitType;
			return `has-text-${type.join("-")}`;
		},
		newCustomSize() {
			return this.customSize || this.customSizeByPack;
		},
		customSizeByPack() {
			if (this.iconConfig && this.iconConfig.sizes) {
				if (
					this.size &&
					this.iconConfig.sizes[this.size] !== undefined
				) {
					return this.iconConfig.sizes[this.size];
				} else if (this.iconConfig.sizes.default) {
					return this.iconConfig.sizes.default;
				}
			}
			return null;
		},
		useIconComponent() {
			return this.component || config.defaultIconComponent;
		},
	},
	methods: {
		/**
		 * Equivalent icon name of the MDI.
		 */
		getEquivalentIconOf(value) {
			//console.log(value);

			let workme = value;

			if (value[1]) {
				workme = value[1];
			}

			// Only transform the class if the both prop is set to true
			if (!this.both) {
				return workme;
			}

			if (
				this.iconConfig &&
				this.iconConfig.internalIcons &&
				this.iconConfig.internalIcons[workme]
			) {
				return this.iconConfig.internalIcons[workme];
			}
			return workme;
		},
	},
};
</script>
