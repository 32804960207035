<template>
	<div>
		<b-navbar type="is-white" class="fixed-top">
			<template slot="brand">
				<b-navbar-item class="logo" tag="router-link" :to="{ path: '/' }"></b-navbar-item>
				<!--<b-navbar-item @click.prevent="sidebarToggle()" class="is-hidden-desktop">
					<b-icon pack="mdi" :icon="menuToggleIcon" size="is-regular"></b-icon>
				</b-navbar-item>-->
			</template>

			<template slot="start"></template>

			<template slot="end"></template>
		</b-navbar>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Branding from "~/components/Branding";

export default {
	components: {
		Branding
	},
	data: () => ({
		appName: process.env.appName,
		breadcrumbs: [],
		creditformatted: 0
	}),
	mounted() {
		this.formatcredit();
	},
	created() {},
	watch: {
		credit(newCount, oldCount) {
			this.formatcredit();
		}
	},
	computed: {
		menuToggleIcon() {
			return this.isSidebarVisible ? "backburger" : "forwardburger";
		},
		url() {
			return process.env.appUrl;
		},
		...mapGetters({
			isSidebarVisible: "main/isSidebarVisible",
			level: "auth/getlevel",
			user: "projects/getuser",
			isAuthenticated: "auth/isAuthenticated",
			tenant_id: "auth/tenant_id"
		})
	},
	methods: {
		async logout() {
			// Log out the user.
			await this.$store.dispatch("auth/logout");

			// Redirect to login.
			this.$router.push({ name: "login" });
		},
		formatcredit() {
			this.creditformatted = this.$moment
				.utc(this.credit * 60000)
				.format("HH[:]mm");

			//this.creditformatted=this.$moment().startOf('day').add(this.credit, 'minutes').format('H:mm')
		},
		sidebarToggle() {
			this.$store.commit("projects/sidebarToggle");
		}
	}
};
</script>
