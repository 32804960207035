// main.js or index.js
import Vue from 'vue'

import InfiniteLoading from 'vue-infinite-loading';

Vue.use(InfiniteLoading, {
	slots: {
		// keep default styles
		noResults: ''
	}
});
