<template>
	<div class="filepicker" :class="'type-' + layout">
		<p
			:class="{ control: layout == 'inline' }"
			v-if="info.platform == 'android' || info.platform == 'ios'"
		>
			<a
				class="button is-light"
				@click="selectFile"
				aria-label="kies bestand"
			>
				<b-icon icon="file" aria-hidden="true" />
				<span class="buttontext">kies bestand</span>
			</a>
		</p>

		<p
			:class="{ control: layout == 'inline' }"
			v-if="info.platform == 'android'"
		>
			<a
				class="button is-light"
				@click="takePicture"
				aria-label="maak foto"
			>
				<b-icon icon="camera" aria-hidden="true"></b-icon>
				<span class="buttontext">maak foto</span>
			</a>
		</p>

		<p
			:class="{ control: layout == 'inline' }"
			v-if="info.platform == 'ios'"
		>
			<a
				class="button is-light"
				@click="takePictureorchoosegallery"
				aria-label="maak foto"
			>
				<b-icon icon="camera" aria-hidden="true"></b-icon>
				<span class="buttontext">maak foto</span>
			</a>
		</p>

		<p
			:class="{ control: layout == 'inline' }"
			v-if="info.platform == 'web'"
		>
			<b-upload
				v-model="files"
				class="file-label"
				@input="addfiles"
				multiple
				drag-drop
				:class="{ 'drop-active': dropActive }"
			>
				<a class="button is-light" aria-label="kies bestand">
					<b-icon aria-hidden="true" icon="upload"></b-icon>
					<span class="buttontext">kies bestand</span>
				</a>
			</b-upload>
		</p>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import {
	Plugins,
	CameraSource,
	CameraResultType,
	Capacitor,
} from "@capacitor/core";
const { Filesystem } = Plugins;
const { Camera } = Plugins;
const { FileSelector } = Plugins;

export default {
	data: () => ({
		//edit_contribution: { file: {} },
		dropActive: false,
		dropAllowed: true,
		files: [],
		info: { platform: null },
		fileloc: null,
		mimeext: {
			"image/png": ["png"],
			"image/bmp": ["bmp"],
			"image/gif": ["gif"],
			"image/webp": ["webp"],
			"image/jpeg": ["jpg", "jpeg", "jpe"],
			"image/svg+xml": ["svg", "svgz"],
			"image/tiff": ["tiff", "tif"],
		},
		file_loc: null,
		file: {},
	}),
	props: {
		layout: null,
		multiple: false,
		field: null,
	},
	computed: {
		url() {
			return process.env.appUrl;
		},
		...mapGetters({
			level: "auth/getlevel",
			user: "projects/getuser",
			isAuthenticated: "auth/isAuthenticated",
			tenant_id: "auth/tenant_id",
			signature: "auth/signature",
		}),
	},
	methods: {
		showdrop(event) {
			if (this.dropAllowed) {
				this.dropActive = true;
			}
		},
		hidedrop() {
			this.dropActive = false;
		},
		addfiles() {
			//let formData = new FormData();
			this.hidedrop();

			var reader = [];
			this.isLoading = true;

			for (var i = this.files.length - 1; i >= 0; i--) {
				reader.push(new FileReader());
				//console.log(i, contribution);
				this.file.name = this.files[i].name;
				this.file.type = this.files[i].type;
				this.file.size = this.files[i].size;

				reader[i].readAsDataURL(this.files[i]);
				reader[i].onload = (e) => {
					this.file.raw = e.target.result;
					this.returnfile();
				};
			}

			this.files = [];
		},
		progressEvent(progressEvent) {
			this.uploadPercent = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
		},
		dragstart() {
			this.dropAllowed = false;
		},
		dragend() {
			this.dropAllowed = true;
		},
		pasteme(event) {
			if (event.clipboardData && event.clipboardData.items) {
				//image = isImage(event.clipboardData.items);

				let items = event.clipboardData.items;
				let i = 0;
				let item;
				var value;
				if (window.clipboardData && window.clipboardData.getData) {
					value = window.clipboardData.getData("Text");
				} else if (event.clipboardData && event.clipboardData.getData) {
					value = event.clipboardData.getData("text/plain");
				}
				value = value.split("\r");

				while (i < items.length) {
					item = items[i];
					console.log(item);
					var file = item.getAsFile();

					if (file) {
						this.uploading = true;

						this.file = {};

						if (value && value[0]) {
							this.file.name = value[0];
						} else {
							let ext = "";
							if (
								item.type &&
								this.mimeext[item.type] &&
								this.mimeext[item.type][0]
							) {
								ext = "." + this.mimeext[item.type][0];
							}

							this.file.name = Date.now() + ext;
						}

						this.file.type = item.type;

						var reader = new FileReader();

						reader.readAsDataURL(file);

						reader.onload = (e) => {
							this.file.raw = e.target.result;
							//alert(this.file.raw);
							this.returnfile();
						};
					}

					i++;
				}
			}
		},
		async takePicture() {
			try {
				//this.loading = true;
				//if (type == "camera") {
					const image = await Camera.getPhoto({
						quality: 90,
						resultType: CameraResultType.Uri,
						source: CameraSource.Prompt,
					}).catch((e) => console.log(e));
				//}
				this.file_loc = Capacitor.convertFileSrc(image.webPath);
				const file = await fetch(this.file_loc).then((r) => r.blob());

				this.file = {};
				this.file.name = Date.now() + "." + image.format;
				this.file.type = file.type;

				var reader = new FileReader();

				reader.readAsDataURL(file);

				reader.onload = (e) => {
					this.file.raw = e.target.result;
					//alert(this.file.raw);
					this.returnfile();
				};
			} catch (error) {
				//this.loading = false;
			}
		},
		async selectFile() {
			try {
				let multiple_selection = true;
				//let ext = [".jpg",".png",".pdf",".jpeg"] // list of extensions
				//let ext = ["jpg", "ImaGes"]; // combination of extensions or category
				//let ext = ["videos", "audios", "images"] // list of all category
				let ext = ["*"]; // Allow any file type
				ext = ext.map((v) => v.toLowerCase());
				const selectedFile = await FileSelector.fileSelector({
					multiple_selection: multiple_selection,
					ext: ext,
				}).catch((e) => alert(e));

				if (this.info.platform == "android") {
					let paths = JSON.parse(selectedFile.paths);
					let original_names = JSON.parse(
						selectedFile.original_names
					);
					let extensions = JSON.parse(selectedFile.extensions);

					for (let index = 0; index < paths.length; index++) {
						let path = paths[index].replace("_capacitor_file_", "");
						this.file_loc = Capacitor.convertFileSrc(path);
						const file = await fetch(this.file_loc).then((r) =>
							r.blob()
						);

						this.file = {};
						this.file.name =
							original_names[index] + extensions[index];
						this.file.type = file.type;

						var reader = new FileReader();

						reader.readAsDataURL(file);

						reader.onload = (e) => {
							this.file.raw = e.target.result;
							//alert(this.contribution.file.raw);
							this.returnfile();
						};
					}
				}
				if (this.info.platform == "ios") {
					//alert(selectedFile);
					for (
						let index = 0;
						index < selectedFile.paths.length;
						index++
					) {
						//alert();

						let path = selectedFile.paths[index];

						const file = await fetch(path).then((r) => r.blob());
						this.file = {};
						this.file.name =
							selectedFile.original_names[index] +
							selectedFile.extensions[index];
						this.file.type = file.type;

						var reader = new FileReader();

						reader.readAsDataURL(file);

						reader.onload = (e) => {
							this.file.raw = e.target.result;
							//alert(this.contribution.file.raw);
							this.returnfile();
						};
					}
				}
			} catch (error) {
				//this.loading = false;
				console.log(error);
			}
			//this.loading = false;
		},
		returnfile() {
			if (this.file.size && this.file.size > 8388608) {
				this.$buefy.toast.open({
					message: "Het bestand is helaas te groot (8MB).",
					type: "is-danger",
				});
			} else {
				if (this.field) {
					this.$emit("filepick", {
						file: this.file,
						field: this.field,
					});
				} else {
					this.$emit("filepick", this.file);
				}
			}

			console.log(this.file.size);
			//this.$emit("filepick", this.file);
		},
	},
	async mounted() {
		//this.$refs.editcontribution.focus();

		this.info = await this.$CapacitorDevice.getInfo();
	},
};
</script>
