import axios from 'axios'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

export default ({ app, store, redirect }) => {
	axios.defaults.baseURL = process.env.apiUrl

	if (process.server) {
		return
	}

	// Request interceptor
	axios.interceptors.request.use((request) => {
		request.baseURL = process.env.apiUrl

		const token = store.getters['auth/token']
		const tenant_id = store.getters['auth/tenant_id']

		if (token) {
			request.headers.common.Authorization = `Bearer ${token}`
		}

		if (tenant_id) {
			request.headers.common.tenant = tenant_id
		}


		//const locale = store.getters['lang/locale']
		//if (locale) {
		//  request.headers.common['Accept-Language'] = locale
		//}

		return request
	})

	// Response interceptor
	axios.interceptors.response.use(response => response, (error) => {
		const { status } = error.response || {}

		if (status >= 500) {
			swal({
				type: 'error',
				title: 'error_alert_title',
				text: 'error_alert_text',
				reverseButtons: true,
				confirmButtonText: 'ok',
				cancelButtonText: 'cancel'
			})
		}

		if (status === 401 && store.getters['auth/check']) {
			swal({
				type: 'warning',
				title: 'token_expired_alert_title',
				text: 'token_expired_alert_text',
				reverseButtons: true,
				confirmButtonText: 'ok',
				cancelButtonText: 'cancel'
			}).then(() => {
				store.commit('auth/LOGOUT')

				redirect({ name: 'login' })
			})
		}

		return Promise.reject(error)
	})
}
