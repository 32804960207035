export default async function ({ store, redirect, route, from, app }) {

	let init = store.getters['main/initiated'];

	if (!init) {

		store.dispatch("main/init", { route: route });

		//let tenant_id = store.auth.tenant_id;
		let tenant_id = store.getters['auth/tenant_id'];

		//let tenant_id = app.$storage.getUniversal('tenant_id')

		//let device = store.getters["main/device"];
		//let device = app.$CapacitorDevice.getInfo();

		if (!tenant_id && route.name != 'choose') {
			//return redirect('/choose')
			let capacitor = window['Capacitor'];
			let device = await store.getters["main/device"];
			//console.log(device);

			if (!capacitor || capacitor.platform == 'web') {
				let sub = window.location.host.split('.')[0];

				if (sub == '192') {
					sub = 'testkerk';
				}

				const { data } = await app.$axios.get('https://api.chrch.org/api/v2/tenants/' + sub + '/id');

				if (data.data.tenant_id) {
					store
						.dispatch("auth/set_tenant_id", data.data.tenant_id)

				} else {
					return redirect('/choose')
				}

			} else {
				return redirect('/choose')
			}
		}

		let token = store.getters['auth/token'];

		if (token) {
			let token_date = app.$storage.getUniversal('token_date');
			//let token_date = await store.getters['auth/token_date'];

			var diff = (Math.floor(Date.now() / 1000) - token_date);
			//console.log(token_date);
			if (diff > 604800) {
				store.dispatch("auth/refreshtoken");
			}

			store.dispatch("auth/setupsocket");
		}

	} else {
		let startdata = store.getters['main/startdata'];
		if (!startdata) {
			store.dispatch("main/fetchstart");
		}
	}





}
