<template>
	<div>
		<div class="dropdown" v-if="isSidebarVisible">
			<div class="background" @click="sidebarHide()"></div>
		</div>

		<div
			class="columns is-mobile is-gapless bottommenu is-multiline"
			v-if="menu && menu.items"
			role="menubar"
		>
			<template v-for="(item, i) in processedmenu">
				<div
					class="column is-one-fifth icon-column menuitem"
					ref="menuitem"
					:key="item.slug"
					@click="doitem(item)"
					:class="{
						disabled: item.minlevelenabled > user_level,
						sidebaronly: !item.always,
						[`button-${item.slug}`]: true,
					}"
					:aria-label="item.label"
					role="menuitem"
				>
					<b-icon
						v-if="item.icon"
						:icon="item.icon"
						size="is-medium"
						aria-hidden="true"
					></b-icon>
					<div v-if="item.label" class="itemlabel">
						{{ item.label }}
					</div>
				</div>

				<div
					v-if="
						(i == 3 && !isSidebarVisible) ||
						(i == menupos && isSidebarVisible)
					"
					class="column is-one-fifth icon-column menuitem button-more"
					ref="menuitem"
					:key="item.id"
					:aria-label="item.label"
					role="menuitem"
				>
					<div @click="sidebarToggle()" v-if="!isSidebarVisible">
						<b-icon aria-hidden="true"  icon="dots-grid" size="is-medium"></b-icon>
						<div class="itemlabel">Meer...</div>
					</div>
					<div @click="sidebarToggle()" v-if="isSidebarVisible">
						<b-icon
							icon="dots-horizontal"
							size="is-medium"
							aria-hidden="true"
						></b-icon>
						<div class="itemlabel">Minder</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
	components: {},
	data: () => ({
		appName: process.env.appName,
	}),
	computed: {
		menuToggleIcon() {
			return this.isSidebarVisible ? "backburger" : "forwardburger";
		},
		url() {
			return process.env.appUrl;
		},
		...mapGetters({
			isSidebarVisible: "main/isSidebarVisible",
			user_level: "auth/user_level",
			isAuthenticated: "auth/isAuthenticated",
			tenant_id: "auth/tenant_id",
			menu: "main/menu",
		}),
		processedmenu() {
			let items = this.lodash.cloneDeep(this.menu.items);
			//items = items.reverse();
			let menu = [];
			let i = 0;

			for (i = 0; i < items.length; i++) {
				//console.log(this.user.family_members[i].id, this.user_id);
				//if (this.user.family_members[i].id == this.user_id) {
				//	returner = true;
				//}
				let item = items[i];

				if (
					item.minlevelvisible <= this.user_level &&
					(!item.maxlevelvisible ||
						item.maxlevelvisible >= this.user_level)
				) {
					menu.unshift(item);
				}
			}

			//console.log(menu);

			while (menu.length % 5 !== 4) {
				let item = {};
				menu.unshift(item);
			}
			//console.log(menu);

			return menu;
		},
		menupos() {
			return this.processedmenu.length - 5;
		},
	},
	mounted() {},
	created() {},
	methods: {
		async logout() {
			this.sidebarHide();
			await this.$store.commit("auth/CLEAR_USER");
			$nuxt.$router.push("/choose");
		},
		sidebarToggle() {
			this.$store.commit("main/sidebarToggle");
			this.$store.dispatch("main/transitioning");
		},
		sidebarHide() {
			this.$store.commit("main/sidebarHide");
			this.$store.dispatch("main/transitioning");
		},
		doitem(item) {
			//console.log(item);
			this.sidebarHide();

			if (
				item.minlevelenabled &&
				item.minlevelenabled > this.user_level
			) {
				this.$buefy.toast.open({
					message: "Helaas niet voldoende rechten",
					type: "is-danger",
				});
			} else if (item && item.label) {
				$nuxt.$router.push(item.path);
			}
		},
	},
};
</script>
