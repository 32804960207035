<template>
	<div :class="{ enlarged: enlarged }">
		<div class="fileviewer">
			<img @click="clickimg()" v-lazy-load :data-src="srcthumb" />
		</div>
		<b-modal class="imagezoom" :active.sync="isModalActive" scroll="keep">
			<img v-lazy-load :data-src="clickedimg" alt="Image" @click="enlarge()" />
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
	name: "Fileviewer",
	props: {
		srcthumb: null,
		srcenlargement: null,
	},
	data: () => ({
		isModalActive: false,
		clickedimg: null,
		enlarged: false,
	}),
	mounted() {},
	methods: {
		clickimg(img) {
			this.clickedimg = this.srcenlargement;
			this.isModalActive = true;
		},
		enlarge() {
			this.enlarged = !this.enlarged;
		},
	},
};
</script>

<style scoped>
</style>
