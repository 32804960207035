<template>
	<div class="reply_edit">
		<form role="form" @submit.prevent="savereply">
			<div>
				<b-field>
					<b-input
						type="textarea"
						name="content"
						v-model="edit_contribution.content"
						placeholder="Type hier je reactie"
						aria-label="Type hier je reacie"
					></b-input>
				</b-field>
				<div class="field is-clearfix">
					<b-button
						class="is-pulled-left"
						type="is-primary"
						native-type="submit"
						:loading="isLoading"
						>reageer</b-button
					>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import FilePicker from "~/components/FilePicker";

export default {
	name: "ContributionReplyEdit",

	data: () => ({
		//edit_contribution: { file: {} },
		dropActive: false,
		dropAllowed: true,
		files: [],
		info: { platform: null },
		fileloc: null,
		mimeext: {
			"image/png": ["png"],
			"image/bmp": ["bmp"],
			"image/gif": ["gif"],
			"image/webp": ["webp"],
			"image/jpeg": ["jpg", "jpeg", "jpe"],
			"image/svg+xml": ["svg", "svgz"],
			"image/tiff": ["tiff", "tif"],
		},
		file_loc: null,
		file: {},
		isLoading: false,
		edit_contribution: { file: null },
	}),
	components: {
		FilePicker,
	},
	props: {
		channel: null,
		contribution_id: null,
		contribution: null,
		parent: null,
	},
	computed: {
		url() {
			return process.env.appUrl;
		},
		...mapGetters({
			level: "auth/getlevel",
			user: "projects/getuser",
			isAuthenticated: "auth/isAuthenticated",
			tenant_id: "auth/tenant_id",
			signature: "auth/signature",
		}),
	},
	methods: {
		savereply() {
			this.isLoading = true;
			var contribution = {};
			contribution.content = this.edit_contribution.content;
			contribution.type = "reply";

			if (this.parent) {
				contribution.parent_id = this.parent.id;
				contribution.channel_id = this.parent.channel_id;
			}

			if (this.edit_contribution.id) {
				contribution.id = this.edit_contribution.id;
			}

			this.$store
				.dispatch("main/savecontribution", contribution)
				.then(() => {
					this.initializing = true;
					this.isLoading = false;
					this.$buefy.toast.open({
						message: "Succesvol opgeslagen",
						type: "is-success",
					});
					this.$emit("close_reply_form");
					this.edit_contribution = { file: null };
					//this.edit_contribution
				});
		},
	},
	async mounted() {
		//this.$refs.editcontribution.focus();
		//this.info = await this.$CapacitorDevice.getInfo();
		//if (this.contribution) {
		//	this.edit_contribution = this.contribution;
		//}
		if (this.contribution) {
			//let contribution = this.contribution;
			let contribution = this.lodash.cloneDeep(this.contribution);
			contribution.file = {};
			this.edit_contribution = contribution;
		}
	},
};
</script>
