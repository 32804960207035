<template>
	<div class="sidebarmenu is-fullheight" role="menu">
		<div class="brand" v-if="!$store.state.main.mobile">
			<branding />
		</div>

		<!--
		<div class="menu" icon-pack="mdi">
			<ul class="menu-list" label v-if="menu">
				<template v-for="item in menu.items">
					<li
						v-if="
							item.minlevelvisible <= user_level &&
							(!item.maxlevelvisible ||
								item.maxlevelvisible >= user_level)
						"
						ref="menuitem"
						:key="item.id"
						@click="doitem(item)"
						:aria-label="item.label"
						class="menuitem"
						:class="{
							disabled: item.minlevelenabled > user_level,
							sidebaronly: !item.always,
							[`button-${item.slug}`]: true,
							[`is-active`]: activeslug == item.slug,
						}"
						role="menuitem"
					>
						<a class="icon-text"
							><b-icon
								:icon="item.icon"
								size="is-small"
								aria-hidden="true"
							/><span>{{ item.label }}</span></a
						>
					</li>
				</template>
			</ul>
		</div>
		-->

		<b-menu icon-pack="mdi">
			<b-menu-list label v-if="menu">
				<template v-for="item in menu.items">
					<b-menu-item
						:icon="item.icon"
						v-if="
							item.minlevelvisible <= user_level &&
							(!item.maxlevelvisible ||
								item.maxlevelvisible >= user_level)
						"
						ref="menuitem"
						:key="item.id"
						@click="doitem(item)"
						:label="item.label"
						:aria-label="item.label"
						class="menuitem"
						:class="{
							disabled: item.minlevelenabled > user_level,
							sidebaronly: !item.always,
							[`button-${item.slug}`]: true,
						}"
						role="menuitem"
					></b-menu-item>
				</template>
			</b-menu-list>
		</b-menu>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import Branding from "~/components/Branding";

export default {
	data() {
		return {
			isActive: true,
			activeslug: "home",
		};
	},
	components: {
		Branding,
	},

	computed: {
		...mapGetters({
			isSidebarVisible: "main/isSidebarVisible",
			user_level: "auth/user_level",
			user_id: "auth/user_id",
			menu: "main/menu",
		}),
	},
	methods: {
		async logout() {
			// Log out the user.
			await this.$store.dispatch("auth/logout");

			// Redirect to login.
			this.$router.push({ name: "login" });
		},
		sidebarToggle() {
			this.$store.commit("projects/sidebarToggle");
		},
		doitem(item) {
			//console.log(item);
			//this.sidebarHide();

			if (
				item.minlevelenabled &&
				item.minlevelenabled > this.user_level
			) {
				this.$buefy.toast.open({
					message: "Helaas niet voldoende rechten",
					type: "is-danger",
				});
			} else {
				this.activeslug = item.slug;
				$nuxt.$router.push(item.path);
			}
		},
	},
};
</script>
