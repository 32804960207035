import Vue from 'vue'
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js'

// The second argument is optional and sets the default config values for every player.
Vue.use(VuePlyr, {
	plyr: {
		fullscreen: { enabled: true },
		preload: false,
		loadSprite: false,
		iconUrl: '/plyr.svg',
		blankVideo: '',
		speed: {
			selected: 1,
			options: [1, 2]
		}
	},
	emit: ['ended']
})
