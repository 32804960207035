import axios from 'axios'
import Vue from 'vue'
import { PinCheck } from "@ionic-native/pin-check";
//import { Push, PushObject, PushOptions } from '@ionic-native/push';

import { Plugins } from '@capacitor/core'
const { PushNotifications } = Plugins
// state
export const state = () => {
	return {
		user: null,
		permissions: null,
		level: null,
		token: null,
		api_url: null,
		media_url: null,
		set_password: false,
		tenant_id: null,
		isSidebarVisible: false,
		start: false,
		startdata: null,
		users: [],
		groups: [],
		path: null,
		channels: [],
		menu: null,
		mobile: true,
		initiated: false,
		device: { platform: '' },
		tenantts: null,
		contribution: [],
		history: [],
		group: null,
		userindexcolumnstate: { scrolltop: 0, search: '' },
		events: [],
		events_selectedcalendars: [],
		events_legend: [],
		guide: [],
		channelscrollpositions: {},
		channelscontributionsloaded: {},
		settings: {},
		transitioning: false,
		permissions: []
		//api_url:process.env.api_url
	}
}

export const getters = {
	startdata(state) {
		return state.startdata;
	},
	isSidebarVisible(state) {
		return state.isSidebarVisible
	},
	tenant_location(state) {
		return state.startdata.location;
	},
	users(state) {
		return state.users;
	},
	groups(state) {
		return state.groups;
	},
	channels(state) {
		return state.channels;
	},
	contributions(state) {
		return state.contributions;
	},
	path(state) {
		return state.path;
	},
	initiated(state) {
		return state.initiated;
	},
	menu(state) {
		return state.menu;
	},
	device(state) {
		return state.device;
	},
	tenantts(state) {
		return state.tenantts;
	},
	contribution(state) {
		return state.contribution;
	},
	mobile(state) {
		return state.mobile;
	},
	history(state) {
		return state.history;
	},
	group(state) {
		return state.group;
	},
	userindexcolumnstate(state) {
		//console.log(state.userindexcolumnstate);
		return state.userindexcolumnstate;
	},
	events(state) {
		return state.events;
	},
	events_selectedcalendars(state) {
		return state.events_selectedcalendars;
	},
	events_legend(state) {
		return state.events_legend;
	},
	legend(state) {
		return state.legend;
	},
	guide(state) {
		return state.guide;
	},
	settings(state) {
		return state.settings;
	},
	permissions(state) {
		return state.permissions;
	},
	transitioning(state) {
		return state.transitioning;
	}
}

// mutations
export const mutations = {
	setstart(state, startdata) {
		state.startdata = startdata;
		let channels = [];

		for (let key in startdata.channels) {
			let channel = startdata.channels[key];
			let ts = 0;
			if (channel.contribution && channel.contribution.published_at) {
				ts = Vue.moment(channel.contribution.published_at).unix();
			}

			channel.ts = ts;
			channels.push(channel);
		}

		channels.sort(function (x, y) {
			return y.ts - x.ts;
		});

		state.channels = channels;
		state.menu = startdata.menu;
		state.tenantts = startdata.tenantts;
		state.groups = startdata.groups;
		state.settings = startdata.settings;

		let bodyStyles = document.body.style;
		bodyStyles.setProperty("--primary-color", startdata.color);
		bodyStyles.setProperty("--primary-color-rgb", startdata.colorrgb);

		var tenant_id = this.$storage.getUniversal('tenant_id')

		if (startdata.bg) {
			//bodyStyles.setProperty("--bg-img", process.env.apiUrl+'/storage/tenants/'+tenant_id+'/bg.jpg');
			bodyStyles.setProperty("--bg-img", 'url(https://api.chrch.org/storage/tenants/' + tenant_id + '/bg.jpg?ts=' + state.tenantts + ')');
		}


	},
	emptystart(state) {
		//console.log("start is now empty, right?");
		state.startdata = null;
		state.events = [];
	},
	emptygroup(state) {
		//console.log("group is now empty, right?");
		state.group = null;
	},
	emptyusers(state) {
		//console.log("users is now empty, right?");
		state.users = null;
	},
	emptyevents(state) {
		//console.log("events is now empty, right?");
		state.events = null;
	},

	emptyall(state) {
		state.user = null;
		state.permissions = null;
		state.level = null;
		state.token = null;
		state.api_url = null;
		state.media_url = null;
		state.set_password = false;
		state.tenant_id = null;
		state.isSidebarVisible = false;
		state.start = false;
		state.startdata = null;
		state.users = [];
		state.groups = [];
		state.path = null;
		state.channels = [];
		state.menu = null;
		state.mobile = true;
		state.initiated = false;
		state.device = { platform: null };
		state.tenantts = null;
		state.contribution = [];
		state.history = [];
		state.group = null;
		state.userindexcolumnstate = { scrolltop: 0, search: '' };
		state.events = [];
		state.events_selectedcalendars = [];
		state.events_legend = [];
		state.guide = [];
		state.channelscrollpositions = {};
		state.channelscontributionsloaded = {};
		state.settings = {};
	},
	setchannelscrollposition(state, options) {
		var channel_id = options.channel_id;
		var scrolltop = options.scrolltop;
		var channel = state.channels.filter(x => x.id == channel_id)[0];
		if (!channel.scrolltop) {
			Vue.set(channel, scrolltop, 0)
		}
		channel.scrolltop = scrolltop;
	},
	new_setchannelscrollposition(state, options) {
		var channel_id = options.channel_id;
		var scrolltop = options.scrolltop;
		state.channelscrollpositions[channel_id] = scrolltop;
	},
	setuserindexcolumnstate(state, options) {
		state.userindexcolumnstate = options;
	},
	seteventindexcolumnstate(state, options) {
		if (options.scrolltop) {
			state.eventindexcolumnstate.scrolltop = options.scrolltop;
		}
		if (options.search) {
			state.eventindexcolumnstate.search = options.search;
		}
		if (options.selectedcalendars) {
			state.eventindexcolumnstate.selectedcalendars = options.selectedcalendars;
		}
	},

	setusers(state, users) {
		state.users = users
	},
	setpermissions(state, permissions) {
		state.permissions = permissions
	},
	setguide(state, guide) {
		state.guide = guide
	},
	setevents(state, events_raw) {

		let events = [];
		let legend = {};

		let today = Vue.moment()
			.startOf("day")
			.unix();

		let prev_ts = null;

		let tempevents = Vue.lodash.cloneDeep(events_raw);

		for (let key in tempevents) {
			//let id = this.data.collections[key].id;
			let event = tempevents[key];
			let ts = Vue.moment(event.start)
				.startOf("day")
				.unix();

			event.start_ts = ts;

			let create_header = false;
			if (ts != prev_ts) {
				event.showheader = 1;
				create_header = true;
			}

			if (event.ts < today) {
				event.history = 1;
			} else {

				event.selected = [];

				for (let key1 in event.fields) {
					let field = event.fields[key1];

					if ($nuxt.$store.state.auth.displayname && $nuxt.$store.state.auth.displayname != '' && field.value.includes($nuxt.$store.state.auth.displayname)) {
						field.mine = true;
						event.selected.push(field.label);
					}
				}

				if (!legend[event.calendar_no]) {
					legend[event.calendar_no] = event.feed_title;
				}

				//event = Object.freeze(event);
				events.push(event);
			}

			prev_ts = ts;
		}

		let selectedcalendars = [];
		for (let key2 in legend) {
			selectedcalendars.push(key2);
		}
		selectedcalendars.push("0");
		console.log(selectedcalendars);


		//console.log(this.legend);

		//state.eventindexcolumnstate.selectedcalendars = selectedcalendars;
		//state.eventindexcolumnstate.legend = legend;
		//state.eventindexcolumnstate.events_raw = events_raw;
		//events = Object.freeze(events);
		state.events = events;
		state.events_selectedcalendars = selectedcalendars;
		state.events_legend = legend;
	},
	setcontribution(state, contribution) {
		state.contribution = contribution
	},
	setchannelprefs(state, prefs) {
		var channel = state.channels.filter(x => x.id == prefs.id)[0];
		channel[prefs.name] = prefs.value;
	},
	setcontributions(state, options) {
		var contributions = options.data;
		var channel_id = options.channel_id;
		var channel = state.channels.filter(x => x.id == channel_id)[0];
		var update = options.update;
		//if (!channel.contributions) {
		//	channel.contributions = [];
		//	Vue.set(channel, contributions, []);
		//}
		var countbefore = channel.contributions.length;

		//console.log(options);
		if (update) {

			let latestcontribution = contributions[0];
			let homechannel = state.channels.filter(x => x.id == channel_id)[0];
			homechannel.contribution = latestcontribution;
			homechannel.oldnews = false;
			if (latestcontribution) {
				homechannel.ts = Vue.moment(latestcontribution.published_at).unix();

			}

			state.channels.sort(function (x, y) {
				return y.ts - x.ts;
			});



			if (channel.contributions.length == countbefore) {
				channel.contibutionsloaded = false;
			}

			channel.contributions = [];

			for (let key in contributions) {
				let new_contribution = contributions[key];
				var contribution = channel.contributions.filter(x => x.id == new_contribution.id);
				if (contribution.length > 0) {
					//contribution[0] = {};
					contribution[0] = new_contribution;
					//console.log(new_contribution);

				} else {
					//channel.contributions.push(new_contribution);
					/*
					if (channel.type == 0) {
						channel.contributions.push(new_contribution);
					} else {
						channel.contributions.unshift(new_contribution);
					}
					*/
					if (channel.type == 0) {
						channel.contributions.unshift(...contributions.reverse());
					} else {
						channel.contributions.push(...contributions);
					}
				}
			}


		} else {
			if (channel.type == 0) {
				channel.contributions.unshift(...contributions.reverse());
			} else {
				channel.contributions.push(...contributions);
			}
			if (channel.contributions.length == countbefore) {
				Vue.set(channel, 'contributionsloaded', true);
			}
		}

	},
	setgroup(state, group) {
		state.group = group
	},

	setpath(state, path) {
		state.path = path
	},
	setmobile(state, mobile) {
		state.mobile = mobile;
	},
	setdevice(state, device) {
		state.device = device;
	},
	add_to_history(state, path) {
		state.history.push(path);
		//console.log(state.history);

	},
	fixhistory(state) {
		state.history.splice(-2);
	},
	sidebarToggle(state) {
		const htmlAsideClassName = 'sidebar-visible'

		let isVisible;

		isVisible = !state.isSidebarVisible

		if (isVisible) {
			document.documentElement.classList.add('sidebar-visible')
			//state.isSidebarVisible=true;
		} else {
			document.documentElement.classList.remove('sidebar-visible')
			//state.isSidebarVisible=false;

		}

		state.isSidebarVisible = isVisible
	},

	sidebarShow(state) {
		const htmlAsideClassName = 'sidebar-visible'

		document.documentElement.classList.add('sidebar-visible')

		state.isSidebarVisible = true;
	},

	sidebarHide(state) {
		//const htmlAsideClassName = 'sidebar-visible'

		document.documentElement.classList.remove('sidebar-visible')

		state.isSidebarVisible = false;
	},

	initiated(state) {
		state.initiated = true;
	},

	denitiate(state) {
		state.initiated = false;
	},

	transitioning(state, what) {
		state.transitioning = what;
	}
}

// actions
export const actions = {
	transitioning({ commit, state, dispatch }) {
		commit('transitioning', true);
		setTimeout(() => {
			commit('transitioning', false);
		}, 200);

	},

	deinitiate({ commit }) {
		commit('initiated');
	},

	async init({ commit, state, dispatch }, route) {
		commit('initiated');

		//console.log(process.env.appLocale);

		if (!state.start && route.route.path != '/' && route.route.path != '/choose' && route.route.path != '/give') {
			dispatch('fetchstart');
		}

		let mobile = true;

		let width = Math.max(
			document.body.scrollWidth,
			document.documentElement.scrollWidth,
			document.body.offsetWidth,
			document.documentElement.offsetWidth,
			document.documentElement.clientWidth
		);

		if (width > 768) {
			mobile = false;
		}

		commit('setmobile', mobile);

		let device = await this.$CapacitorDevice.getInfo();

		commit('setdevice', device);

		if (device.platform == "android" || device.platform == "ios") {


			await PinCheck.isPinSetup(
				function (success) {
					//alert("pin is setup.");

				},
				function (fail) {
					//alert("pin not setup.");
					//destroy auth details
					commit('auth/CLEAR_USER', null, { root: true })

				}
			);



			/*
			const options = {
				android: {},
				ios: {
					alert: 'true',
					badge: true,
					sound: 'false'
				},
				windows: {}
			};
			*/


			//const pushObject = PushObject.init(options);

			//pushObject.on('notification').subscribe((notification) => console.log('Received a notification', notification));

			//pushObject.on('registration').subscribe((registration) => alert(registration.registrationId));
		}




	},
	async fetchstart({ commit, state }, force) {

		//let id = route.route.params.space_id;
		//console.log(state.startdata);

		if (state.startdata && !force) {
			//return;
		} else {
			try {
				const { data } = await axios.get('/v2/start');

				commit('setstart', data.data)

				commit(
					"auth/setuser_credit",
					data.data["user_credit"],
					{ root: true }
				);

			} catch (e) {

				console.log(e);
			}
		}
	},
	async fetchusers({ commit, state }) {

		//let id = route.route.params.space_id;

		if (state.users && state.users.length > 0) {
			//return;
		} else {


			let endpoint = '/v2/users';
			let user_level = $nuxt.$store.state.auth.user_level;

			if (user_level && user_level > 1) {
				endpoint = endpoint + '?nonpublic=true'
			}


			try {
				const { data } = await axios.get(endpoint);

				commit('setusers', data.data)
			} catch (e) {

				console.log(e);
			}
		}
	},
	async fetchpermissions({ commit, state }) {

		//let id = route.route.params.space_id;

		if (state.permissions && state.permissions.length > 0) {
			//return;
		} else {

			let endpoint = '/v2/users/permissions';

			try {
				const { data } = await axios.get(endpoint);
				console.log(data.data);

				commit('setpermissions', data.data)
			} catch (e) {

				console.log(e);
			}
		}
	},
	async fetchevents({ commit, state }) {

		//let id = route.route.params.space_id;

		if (state.events && state.events.length > 0) {
			//return;
		} else {
			try {
				const { data } = await axios.get('v2/events');

				commit('setevents', data.data)
			} catch (e) {

				console.log(e);
			}
		}
	},
	async fetchguide({ commit, state }) {

		//let id = route.route.params.space_id;

		if (state.guide && state.guide.length > 0) {
			//return;
		} else {
			try {
				const { data } = await axios.get('getguide');
				commit('setguide', data.guide)
			} catch (e) {

				console.log(e);
			}
		}
	},

	async fetchfirstchannelcontributions({ commit, dispatch, state }, channel_id) {
		var channel = state.channels.filter(x => x.id == channel_id)[0];
		if (channel.contributions.length == 0) {
			var option = {};
			option.channel_id = channel_id;
			await dispatch('fetchchannelcontributions', option);
		}
	},
	async fetchmorechannelcontributions({ commit, dispatch, state }, channel_id) {
		var option = {};
		option.channel_id = channel_id;
		await dispatch('fetchchannelcontributions', option);
	},
	async fetchnewchannelcontributions({ commit, dispatch, state }, channel_id) {
		var option = {};
		option.channel_id = channel_id;
		option.update = true;
		await dispatch('fetchchannelcontributions', option);
	},
	path({ commit, state }, path) {
		commit('setpath', path)

	},
	async fetchchannelcontributions({ commit, state }, option) {
		var channel_id = option.channel_id;
		var update = false;
		var take = 20;
		try {
			var channel = state.channels.filter(x => x.id == channel_id)[0];
			var start = 0;

			if (option.update) {
				update = option.update;
				take = channel.contributions.length

				if (take < 20) {
					take = 20;
				}
			}


			if (!update) {
				start = channel.contributions.length;
			}
			const { data } = await axios.get('/v2/channels/' + channel_id + '/contributions?start=' + start + '&take=' + take);
			commit('setcontributions', { data: data.data, channel_id: channel_id, update: update })
		} catch (e) {

			console.log(e);
		}
	},
	async fetchcontribution({ commit, state }, id) {
		const { data } = await axios.get('/v2/contributions/' + id);
		//console.log(data.data);
		commit('setcontribution', data.data);
	},
	async fetchgroup({ commit, state }, payload) {
		//console.log(force);
		if (state.group == null || state.group.id != payload.id || payload.force) {
			const { data } = await axios.get('/v2/groups/' + payload.id);
			commit('setgroup', data.data);
		}
	},

	async savecontribution({ commit, state, dispatch }, contribution) {
		if (contribution.id) {
			await axios
				.put("/v2/contributions/" + contribution.id, contribution)
				.then(({ data }) => {
					//if (!contribution.parent_id) {
					dispatch('fetchnewchannelcontributions', contribution.channel_id);
					//}
				})
				.catch(err => {
					throw err;

					console.log(err);
				});
		} else {
			await axios
				.post("/v2/contributions", contribution)
				.then(({ data }) => {

				})
				.catch(err => {
					throw err;

					console.log(err);
				});

		}
		if (contribution.parent_id) {
			dispatch('fetchcontribution', state.contribution.id);
		}
	},
	async deletecontribution({ commit, dispatch, state }, contribution) {
		if (contribution.id) {
			await axios
				.delete("/v2/contributions/" + contribution.id)
				.then(({ data }) => {
					var channel = state.channels.filter(x => x.id == contribution.channel_id)[0];
					if (channel && channel.contributions) {
						//var delcontribution = channel.contributions.filter(
						//	x => x.id == contribution.id
						//)[0];

						//channel.contributions = channel.contributions.filter(item => item.id != contribution.id);
						//console.log(delcontribution);
						dispatch('fetchnewchannelcontributions', contribution.channel_id);

					}


				})
				.catch(err => {
					throw err;

					console.log(err);
				});
		}

	},
	async deletechannel({ commit, state }, channel) {
		if (channel.id) {
			await axios
				.delete("/v2/channels/" + channel.id)
				.then(({ data }) => {

					/*
					var channel = state.channels.filter(x => x.id == channel.channel_id)[0];
					if (channel && channel.channels) {

						channel.channels = channel.channels.filter(item => item.id != channel.id);
					}
					*/


				})
				.catch(err => {
					throw err;

					console.log(err);
				});
		}

	},
	async deleteuser({ commit, state }, user) {
		if (user.id) {
			await axios
				.delete("/v2/users/" + user.id)
				.then(({ data }) => {

				})
				.catch(err => {
					throw err;

					console.log(err);
				});
		}

	},

	async deletegroup({ commit, state }, group) {
		if (group.id) {
			await axios
				.delete("/v2/groups/" + group.id)
				.then(({ data }) => {

				})
				.catch(err => {
					throw err;

					console.log(err);
				});
		}

	},
	async saveuser({ commit, state }, user) {
		if (user.id) {
			await axios
				.put("/v2/users/" + user.id, user)
				.then(({ data }) => {

				})
				.catch(err => {
					throw err;
					console.log(err);
				});
		} else {
			await axios
				.post("/v2/users", user)
				.then(({ data }) => {

				})
				.catch(err => {
					throw err;
					console.log(err);
				});

		}

	},
	async savegroup({ commit, state }, group) {
		if (group.id) {
			await axios
				.put("/v2/groups/" + group.id, group)
				.then(({ data }) => {

				})
				.catch(err => {
					throw err;
					console.log(err);
				});
		} else {
			await axios
				.post("/v2/groups", group)
				.then(({ data }) => {

				})
				.catch(err => {
					throw err;
					console.log(err);
				});

		}

	},
	async savechannel({ commit, state }, channel) {
		if (channel.id) {
			await axios
				.put("/v2/channels/" + channel.id, channel)
				.then(({ data }) => {

				})
				.catch(err => {
					throw err;
					console.log(err);
				});
		} else {
			await axios
				.post("/v2/channels", channel)
				.then(({ data }) => {

				})
				.catch(err => {
					throw err;

					console.log(err);
				});
		}
	},
	async emptystart({ commit, state }) {
		commit('emptystart')
	},
	async emptygroup({ commit, state }) {
		commit('emptygroup')
	},
	async emptyall({ commit, state }) {
		commit('emptyall')
	},
	async emptyusers({ commit, state }) {
		commit('emptyusers')
	},
	async emptyevents({ commit, state }) {
		commit('emptyevents')
	},

	async back({ commit, state }, routename) {
		let goto = "/";
		if (state.history.length > 1) {
			goto = state.history[state.history.length - 2];
		}
		console.log(goto);
		commit("fixhistory");
		$nuxt.$router.push(goto);
	},

	async old_back({ state }, routename) {
		let back = "/";

		if (
			$nuxt.$route.name == "admin-index-users-user-edit" ||
			$nuxt.$route.name == "admin-index-users-add" ||
			$nuxt.$route.name == "admin-index-dashboard" ||
			$nuxt.$route.name == "admin-index-users" ||
			$nuxt.$route.name == "admin-index-groups" ||
			$nuxt.$route.name == "admin-index-groups-group-edit" ||
			$nuxt.$route.name == "admin-index-groups-add" ||
			$nuxt.$route.name == "admin-index-channels" ||
			$nuxt.$route.name == "admin-index-channels-channel-edit" ||
			$nuxt.$route.name == "admin-index-channels-add" ||
			$nuxt.$route.name == "admin-index-feeds" ||
			$nuxt.$route.name == "admin-index-feeds-feed-edit" ||
			$nuxt.$route.name == "admin-index-feeds-add" ||
			$nuxt.$route.name == "admin-index-settings" ||
			$nuxt.$route.name == "admin-index-collections" ||
			$nuxt.$route.name == "admin-index-collections-collection-edit" ||
			$nuxt.$route.name == "admin-index-collections-add"
		) {
			//admin routes
			back = "/admin";

			if (
				$nuxt.$route.name == "admin-index-users-user-edit" ||
				$nuxt.$route.name == "admin-index-users-add"
			) {
				back = "/admin/users";
			} else if (
				$nuxt.$route.name == "admin-index-groups-group-edit" ||
				$nuxt.$route.name == "admin-index-groups-add"
			) {
				back = "/admin/groups";
			} else if (
				$nuxt.$route.name == "admin-index-channels-channel-edit" ||
				$nuxt.$route.name == "admin-index-channels-add"
			) {
				back = "/admin/channels";
			} else if (
				$nuxt.$route.name == "admin-index-feeds-feed-edit" ||
				$nuxt.$route.name == "admin-index-feeds-add"
			) {
				back = "/admin/feeds";
			} else if (
				$nuxt.$route.name ==
				"admin-index-collections-collection-edit" ||
				$nuxt.$route.name == "admin-index-collections-add"
			) {
				back = "/admin/collections";
			}
		} else if (
			$nuxt.$route.name == "account-index-user-edit" ||
			$nuxt.$route.name == "account-index-add"
		) {
			back = "/account";
		} else if (
			$nuxt.$route.name == "events-index-event" &&
			$nuxt.$route.params.event
		) {
			back = "/events";
		} else if (
			($nuxt.$route.params.channel &&
				$nuxt.$route.params.contribution) ||
			$nuxt.$route.name == "channels-index-channel-index-add"
		) {
			back = "/channels/" + $nuxt.$route.params.channel;
		} else if ($nuxt.$route.params.channel) {
			back = "/channels";
		} else if ($nuxt.$route.params.channel && $nuxt.$route.params.group) {
			back = "/groups/" + $nuxt.$route.params.group;
		} else if ($nuxt.$route.params.user && $nuxt.$route.params.group) {
			back = "/groups/" + $nuxt.$route.params.group;
		} else if ($nuxt.$route.params.group) {
			back = "/groups";
		} else if ($nuxt.$route.name == "users-index-user-edit") {
			back = "/users/" + $nuxt.$route.params.user;
		} else if (
			$nuxt.$route.params.user ||
			$nuxt.$route.name == "users-index-map"
		) {
			back = "/users";
		}

		$nuxt.$router.push(back);
	}

}
