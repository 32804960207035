<template>
	<div>


		<div class="simple-layout ">
			<nuxt />
		</div>
	</div>
</template>

<script>
import Navbar from "~/components/Navbar";

export default {
	components: {
		Navbar
	},
	mounted() {}
};
</script>
