import axios from 'axios'
import io from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
import Vue from 'vue'

// state
export const state = () => {
	return {
		user: null,
		user_level: -1,
		token: null,
		api_url: null,
		media_url: null,
		set_password: false,
		tenant_id: null,
		user_id: null,
		user_credit: null,
		firstname: null,
		displayname: null,
		socket_token: null,
		signature: null,
		token_date: 0,
		collectionamounts: {},
		latestcollectionamount: 0,
		permissions: {}
	}
}



export const getters = {
	isAuthenticated(state) {

		if (state.token) {
			return true;
		} else {
			return false;
		}
		//return !!state.token
	},
	loggedUser(state) {
		return state.user
	},
	hasPermission: (state) => (permission) => {
		return !!state.permissions.find((p) => p.name === permission)
	},
	token(state) {
		return state.token
	},
	token_date(state) {
		return state.token_data
	},

	getlevel(state) {
		return state.level
	},
	tenant_id(state) {
		return state.tenant_id
	},
	isTenantId(state) {
		return !!state.tenant_id
	},
	user_id(state) {
		return state.user_id
	},
	user_level(state) {
		let user_level = -1;

		if (state.user_level != null && state.user_level != undefined) {
			user_level = state.user_level;
		}
		return user_level;
	},
	user_credit(state) {
		return state.user_credit
	},
	signature(state) {
		return state.signature
	},
	socket_token(state) {
		return state.socket_token
	},
	collectionamounts(state) {
		return state.collectionamounts;
	},
	latestcollectionamount(state) {
		return state.latestcollectionamount;
	},
	firstname(state) {
		return state.firstname
	},
	displayname(state) {
		return state.displayname
	},
	permissions(state) {

		if (!state.permissions || state.permissions == null) {
			return {};
		} else {
			return state.permissions;

		}

	},

}


// mutations
export const mutations = {

	test(state) {
		alert('test');
	},

	set_collectionamounts(state, collectionamounts) {
		state.collectionamounts = collectionamounts;
		this.$storage.setUniversal('collectionamounts', collectionamounts);
	},

	SET_TOKEN(state, token) {
		state.token = token
		this.$storage.setUniversal('token', token || null)
	},

	SET_API_URL(state, api_url) {
		state.api_url = api_url;
	},

	SET_MEDIA_URL(state, media_url) {
		state.media_url = media_url;
	},


	FETCH_USER_SUCCESS(state, user) {
		state.user = user
	},

	FETCH_USER_FAILURE(state) {
		state.token = null
	},

	LOGOUT(state) {
		state.user = null
		state.token = null
	},

	UPDATE_USER(state, { user }) {
		state.user = user
	},

	set_tenant_id(state, tenant_id) {
		this.$storage.setUniversal('tenant_id', tenant_id || null);
		state.tenant_id = tenant_id;
	},
	SET_USER(state, payload) {

		//dispatch("main/emptystart");

		//console.log(payload);
		var token_date = Math.floor(Date.now() / 1000);
		this.$storage.setUniversal('user', payload || null)
		this.$storage.setUniversal('user_id', payload.user_id || null)
		this.$storage.setUniversal('user_level', payload.user_level || 0)
		this.$storage.setUniversal('user_credit', payload.user_credit || null)
		this.$storage.setUniversal('signature', payload.signature || null)
		this.$storage.setUniversal('firstname', payload.firstname || null)
		this.$storage.setUniversal('displayname', payload.displayname || null)
		this.$storage.setUniversal('token', payload.token || null)
		this.$storage.setUniversal('socket_token', payload.socket_token || null)
		this.$storage.setUniversal('token_exp', payload.token_exp || null)
		this.$storage.setUniversal('token_date', token_date || null)
		this.$storage.setUniversal('permissions', payload.permissions || null)


		if (payload.token) {
			// set bearer token
			this.$axios.setToken(payload.token, 'Bearer')
			//console.log(payload);

		}

		state.user = payload || null
		state.user_id = payload.user_id || null
		state.user_level = payload.user_level || 0
		state.user_credit = payload.user_credit || null
		state.token = payload.token || null
		state.signature = payload.signature || null;
		state.firstname = payload.firstname || null;
		state.displayname = payload.displayname || null;
		state.socket_token = payload.socket_token || null
		state.token_exp = payload.token_exp || null
		state.token_date = token_date
		state.permissions = payload.permissions || null;

		//console.log(state);
	},
	reset_token_date() {
		var token_date = 0;
		this.$storage.setUniversal('token_date', token_date);
		state.token_date = token_date;
	},
	setuser_credit(state, payload) {
		this.$storage.setUniversal('user_credit', payload || null)
		state.user_credit = payload || null
		//console.log(payload);
	},
	GET_USER(state) {

		state.user = this.$storage.getUniversal('user')
		state.user_id = this.$storage.getUniversal('user_id')
		state.user_level = this.$storage.getUniversal('user_level')
		state.user_credit = this.$storage.getUniversal('user_credit')
		state.signature = this.$storage.getUniversal('signature')
		state.firstname = this.$storage.getUniversal('firstname')
		state.displayname = this.$storage.getUniversal('displayname')
		state.token = this.$storage.getUniversal('token')
		state.socket_token = this.$storage.getUniversal('socket_token')
		state.token_exp = this.$storage.getUniversal('token_exp')
		state.token_date = this.$storage.getUniversal('token_date')
		state.collectionamounts = this.$storage.getUniversal('collectionamounts')
		state.latestcollectionamount = this.$storage.getUniversal('latestcollectionamount')
		state.permissions = this.$storage.getUniversal('permissions')
	},

	CLEAR_USER(state) {
		// unset authorization token
		this.$axios.setToken(false)
		this.$storage.removeUniversal('user')
		this.$storage.removeUniversal('user_id')
		this.$storage.removeUniversal('user_level')
		this.$storage.removeUniversal('user_credit')
		this.$storage.removeUniversal('signature')
		this.$storage.removeUniversal('firstname')
		this.$storage.removeUniversal('displayname')

		this.$storage.removeUniversal('token')
		this.$storage.removeUniversal('socket_token')
		this.$storage.removeUniversal('token_exp')
		this.$storage.removeUniversal('token_date')
		this.$storage.removeUniversal('tenant_id')
		this.$storage.removeUniversal('permissions')


		state.user = null;
		state.user_id = null
		state.user_level = null
		state.user_credit = null
		state.token = null
		state.signature = null
		state.firstname = null
		state.displayname = null
		state.socket_token = null
		state.token_exp = null
		state.token_date = null
		state.tenant_id = null
		state.permissions = {};

	}
}

// actions
export const actions = {
	saveToken({ commit, dispatch }, { token, remember }) {
		commit('SET_TOKEN', token)
	},
	set_tenant_id({ commit, dispatch }, tenant_id) {
		//console.log(tenant_id);
		commit('set_tenant_id', tenant_id)
	},

	reset_token_date({ commit }) {
		commit('reset_token_date')
	},

	set_user({ commit, dispatch }, user) {
		dispatch("main/emptystart", null, { root: true });
		commit('SET_USER', user)
	},
	async refreshtoken({ commit, state, dispatch }) {
		//const { data } = await axios.get('v2/auth/refreshtoken')
		//dispatch("auth/set_user", data);
		//commit('SET_USER', data)


		await axios.get('v2/auth/refreshtoken')
			.then((resp) => {
				commit('SET_USER', resp.data)
				//resolve(resp.data)
			})
			.catch((err) => {
				console.log(err);
			})


	},

	set_collectionamounts({ commit, state, dispatch }, collectionamounts) {
		//dispatch("auth/set_user", data);

		let newcollectionamounts = {};
		for (let key in state.collectionamounts) {
			newcollectionamounts[key] = state.collectionamounts[key];
		}

		let latestcollectionamount = null;
		for (let key in collectionamounts) {
			if (collectionamounts[key]) {
				newcollectionamounts[key] = collectionamounts[key];
				latestcollectionamount = collectionamounts[key];

			}
		}

		if (latestcollectionamount) {
			commit('set_latestcollectionamount', latestcollectionamount)
		}
		commit('set_collectionamounts', newcollectionamounts)
	},


	async fetchUser({ commit }) {
		try {
			const { data } = await axios.get('/user')

			commit('FETCH_USER_SUCCESS', data)
		} catch (e) {
			//Cookies.remove('token')

			//commit('FETCH_USER_FAILURE')
		}
	},

	updateUser({ commit }, payload) {
		commit('UPDATE_USER', payload)
	},

	async logout({ commit }) {
		try {
			await axios.post('/logout')
		} catch (e) { }
		dispatch("main/emptyall");


		commit('LOGOUT')
		commit('CLEAR_USER')

	},

	login({ commit }, data) {
		return new Promise((resolve, reject) => {
			this.$axios
				.post('v2/auth/login', data)
				.then((resp) => {
					//console.log(resp);
					commit('SET_USER', resp.data)
					resolve(resp.data)
				})
				.catch((err) => {
					commit('CLEAR_USER')
					//console.log(err);
					reject(err)
				})
		})
	},
	register({ commit }, data) {
		return new Promise((resolve, reject) => {
			this.$axios
				.post('users/register', data)
				.then((resp) => {
					//console.log(resp);
					commit('SET_USER', resp.data)
					resolve(resp.data)
				})
				.catch((err) => {
					//commit('CLEAR_USER')
					console.log(err);
					reject(err)
				})
		})
	},
	registerexpanded({ commit }, data) {
		return new Promise((resolve, reject) => {
			this.$axios
				.post('users/registerexpanded', data)
				.then((resp) => {
					//console.log(resp);
					commit('SET_USER', resp.data)
					resolve(resp.data)
				})
				.catch((err) => {
					//commit('CLEAR_USER')
					console.log(err);
					reject(err)
				})
		})
	},
	loginwithtoken({ commit }, token) {
		return new Promise((resolve, reject) => {
			this.$axios
				.post('users/loginwithtoken', { 'token': token })
				.then((resp) => {
					//console.log(resp);
					commit('SET_USER', resp.data)

					if (resp.data && resp.data.set_password) {
						$nuxt.$buefy.snackbar.open({
							message: 'Stel voordat je verdergaat een wachtwoord in',
							actionText: 'Oké',
							indefinite: true,
							onAction: () => {
								$nuxt.$router.push('/account');

							}
						})
					}

					resolve(resp.data)
				})
				.catch((err) => {
					//commit('CLEAR_USER')
					console.log(err);
					reject(err)
				})
		})
	},

	async fetchOauthUrl(ctx, { provider }) {
		const { data } = await axios.post(`/oauth/${provider}`)

		return data.url
	},
	setupsocket() {
		console.log('setupsocket');

		//alert(Vue.prototype.$socket.connected);

		//console.log('nee?');

		//this._vm.$socket.client.emit("authenticate", { token: this.token });


		//this._vm.$socket.$subscribe('event_name', payload => {
		///	console.log(payload)
		//});

		//const socket = io(process.env.socketUrl);


		/*
				Vue.prototype.$socket.client.on('connection', () => {
					Vue.prototype.$socket.emit('authenticate', { token: this.token })
					Vue.prototype.$socket.emit('room', this.socket_token);

					alert('connect...');


				});
				*/
		//console.log('sock for message');
		//	socket.on('message2', (data) => {
		//		console.log('go process');
		//		this.processsocket(data);
		//	});
	}




}
