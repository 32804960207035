<template>
	<div
		:class="{
			mobile: $store.state.main.mobile,
			notmobile: !$store.state.main.mobile,
			[`platform-${device.platform}`]: true,
			[`route-${$route.name}`]: true,
			transitioning: transitioning,
			[`userlevel-${user_level}`]: true,
		}"
	>
		<div class="iphonespacer-top" />
		<sidebar />

		<!--<navbar />-->

		<div class="maincontent">
			<div class="columns is-gapless">
				<div class="column">
					<nuxt :keep-alive="keepalive" />
				</div>
			</div>
		</div>

		<bottom-navbar v-if="$store.state.main.mobile" />
		<socket :key="user_id" />
	</div>
</template>

<script>
import Navbar from "~/components/Navbar";
import Sidebar from "~/components/Sidebar";
import BottomNavbar from "~/components/BottomNavbar";
import Socket from "~/components/Socket";

import { mapGetters, mapState } from "vuex";

export default {
	head() {
		return {
			link: [
				{
					rel: "stylesheet",
					href:
						process.env.appUrl +
						"/tenants/" +
						this.$store.state.auth.tenant_id +
						"/css?ts=" +
						this.$store.state.main.tenantts,
				},
			],
		};
	},
	components: {
		Navbar,
		Sidebar,
		BottomNavbar,
		Socket,
	},

	/*
	watch: {
		$route: function (to, from) {
			this.transitioning = true;
			setTimeout(() => {
				this.transitioning = false;
			}, 200);
		},
	},
*/
	url() {
		return process.env.appUrl;
	},
	computed: {
		...mapGetters({
			tenant_id: "auth/tenant_id",
			isSidebarVisible: "main/isSidebarVisible",
			tenantts: "main/tenantts",
			user_id: "auth/user_id",
			device: "main/device",
			transitioning: "main/transitioning",
			user_level: "auth/user_level",
		}),
	},
	data: () => ({
		keepalive: false,
	}),
	async mounted() {
		//document.documentElement.classList.add("sidebar-visible");
		//this.device = await this.$CapacitorDevice.getInfo();
		//console.log(this.device);
		//console.log(this.$isMobile());
		if (!this.$store.state.main.mobile) {
			this.$store.commit("main/sidebarShow");
		}

		//if (process.client && window) {
		//	window.history.scrollRestoration = "auto";
		//}

		const appHeight = () => {
			document.body.style.setProperty(
				"--app-height",
				`${window.innerHeight}px`
			);
			this.$store.dispatch("main/transitioning");
		};
		window.addEventListener("resize", appHeight);
		appHeight();
	},
	methods: {},
};
</script>
