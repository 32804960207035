var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebarmenu is-fullheight",attrs:{"role":"menu"}},[(!_vm.$store.state.main.mobile)?_c('div',{staticClass:"brand"},[_c('branding')],1):_vm._e(),_vm._v(" "),_c('b-menu',{attrs:{"icon-pack":"mdi"}},[(_vm.menu)?_c('b-menu-list',{attrs:{"label":""}},[_vm._l((_vm.menu.items),function(item){
var _obj;
return [(
						item.minlevelvisible <= _vm.user_level &&
						(!item.maxlevelvisible ||
							item.maxlevelvisible >= _vm.user_level)
					)?_c('b-menu-item',{key:item.id,ref:"menuitem",refInFor:true,staticClass:"menuitem",class:( _obj = {
						disabled: item.minlevelenabled > _vm.user_level,
						sidebaronly: !item.always
					}, _obj[("button-" + (item.slug))] = true, _obj ),attrs:{"icon":item.icon,"label":item.label,"aria-label":item.label,"role":"menuitem"},on:{"click":function($event){return _vm.doitem(item)}}}):_vm._e()]})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }