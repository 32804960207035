var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isSidebarVisible)?_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"background",on:{"click":function($event){return _vm.sidebarHide()}}})]):_vm._e(),_vm._v(" "),(_vm.menu && _vm.menu.items)?_c('div',{staticClass:"columns is-mobile is-gapless bottommenu is-multiline",attrs:{"role":"menubar"}},[_vm._l((_vm.processedmenu),function(item,i){
var _obj;
return [_c('div',{key:item.slug,ref:"menuitem",refInFor:true,staticClass:"column is-one-fifth icon-column menuitem",class:( _obj = {
					disabled: item.minlevelenabled > _vm.user_level,
					sidebaronly: !item.always
				}, _obj[("button-" + (item.slug))] = true, _obj ),attrs:{"aria-label":item.label,"role":"menuitem"},on:{"click":function($event){return _vm.doitem(item)}}},[(item.icon)?_c('b-icon',{attrs:{"icon":item.icon,"size":"is-medium","aria-hidden":"true"}}):_vm._e(),_vm._v(" "),(item.label)?_c('div',{staticClass:"itemlabel"},[_vm._v("\n\t\t\t\t\t"+_vm._s(item.label)+"\n\t\t\t\t")]):_vm._e()],1),_vm._v(" "),(
					(i == 3 && !_vm.isSidebarVisible) ||
					(i == _vm.menupos && _vm.isSidebarVisible)
				)?_c('div',{key:item.id,ref:"menuitem",refInFor:true,staticClass:"column is-one-fifth icon-column menuitem button-more",attrs:{"aria-label":item.label,"role":"menuitem"}},[(!_vm.isSidebarVisible)?_c('div',{on:{"click":function($event){return _vm.sidebarToggle()}}},[_c('b-icon',{attrs:{"aria-hidden":"true","icon":"dots-grid","size":"is-medium"}}),_vm._v(" "),_c('div',{staticClass:"itemlabel"},[_vm._v("Meer...")])],1):_vm._e(),_vm._v(" "),(_vm.isSidebarVisible)?_c('div',{on:{"click":function($event){return _vm.sidebarToggle()}}},[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-medium","aria-hidden":"true"}}),_vm._v(" "),_c('div',{staticClass:"itemlabel"},[_vm._v("Minder")])],1):_vm._e()]):_vm._e()]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }