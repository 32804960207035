<template>
	<div class="branding" aria-hidden="true">
		<nuxt-link to="/">
			<svg
				v-if="!tenant_id"
				width="3261px"
				height="768px"
				viewBox="-1931 -407 3261 768"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				class="logo"
			>
				<!-- Generator: Sketch 39.1 (31720) - http://www.bohemiancoding.com/sketch -->
				<desc>Created with Sketch.</desc>
				<defs>
					<polygon id="path-1" points="0 768 1024 768 1024 0 0 0" />
				</defs>
				<path
					d="M-1568.4,-44.2 C-1579.6,-138.6 -1647.6,-185.8 -1735.6,-185.8 C-1862,-185.8 -1930.8,-85.8 -1930.8,32.6 C-1930.8,151 -1862,251 -1735.6,251 C-1643.6,251 -1578.8,190.2 -1565.2,91 L-1615.6,91 C-1622,159.8 -1673.2,208.6 -1735.6,208.6 C-1832.4,208.6 -1880.4,120.6 -1880.4,32.6 C-1880.4,-55.4 -1832.4,-143.4 -1735.6,-143.4 C-1670,-143.4 -1632.4,-105.8 -1618.8,-44.2 L-1568.4,-44.2 Z M-1386.01096,239 L-1335.61096,239 L-1335.61096,-1.8 C-1334.01096,-82.6 -1286.01096,-143.4 -1206.01096,-143.4 C-1124.41096,-143.4 -1101.21096,-89.8 -1101.21096,-18.6 L-1101.21096,239 L-1050.81096,239 L-1050.81096,-26.6 C-1050.81096,-125 -1086.01096,-185.8 -1202.01096,-185.8 C-1260.41096,-185.8 -1315.61096,-152.2 -1334.01096,-102.6 L-1335.61096,-102.6 L-1335.61096,-332.2 L-1386.01096,-332.2 L-1386.01096,239 Z M-846.021915,239 L-795.621915,239 L-795.621915,19 C-795.621915,-66.6 -733.221915,-133.8 -642.021915,-129 L-642.021915,-179.4 C-716.421915,-182.6 -772.421915,-143.4 -798.021915,-77 L-799.621915,-77 L-799.621915,-173.8 L-846.021915,-173.8 L-846.021915,239 Z M-157.232872,-44.2 C-168.432872,-138.6 -236.432872,-185.8 -324.432872,-185.8 C-450.832872,-185.8 -519.632872,-85.8 -519.632872,32.6 C-519.632872,151 -450.832872,251 -324.432872,251 C-232.432872,251 -167.632872,190.2 -154.032872,91 L-204.432872,91 C-210.832872,159.8 -262.032872,208.6 -324.432872,208.6 C-421.232872,208.6 -469.232872,120.6 -469.232872,32.6 C-469.232872,-55.4 -421.232872,-143.4 -324.432872,-143.4 C-258.832872,-143.4 -221.232872,-105.8 -207.632872,-44.2 L-157.232872,-44.2 Z M25.1561707,239 L75.5561707,239 L75.5561707,-1.8 C77.1561707,-82.6 125.156171,-143.4 205.156171,-143.4 C286.756171,-143.4 309.956171,-89.8 309.956171,-18.6 L309.956171,239 L360.356171,239 L360.356171,-26.6 C360.356171,-125 325.156171,-185.8 209.156171,-185.8 C150.756171,-185.8 95.5561707,-152.2 77.1561707,-102.6 L75.5561707,-102.6 L75.5561707,-332.2 L25.1561707,-332.2 L25.1561707,239 Z"
					id="chrch"
					stroke="none"
					fill="#000000"
					fill-rule="evenodd"
				/>
				<g
					id="Page-1"
					stroke="none"
					stroke-width="1"
					fill="none"
					fill-rule="evenodd"
					transform="translate(306.000000, -407.000000)"
				>
					<path
						d="M243.3662,651.0898 L243.3662,513.5838"
						id="Stroke-1"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M812.3657,651.0898 L811.3657,514.5838"
						id="Stroke-2"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M248.3408,645.0898 L815.5768,645.0898"
						id="Stroke-3"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M446.3662,650.7041 L446.3662,308.2981"
						id="Stroke-4"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M610.3657,650.7041 L610.3657,308.2981"
						id="Stroke-5"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M240.3662,518.7988 L449.1202,424.5888"
						id="Stroke-6"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M815.4282,519.7988 L612.1192,425.5888"
						id="Stroke-7"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M611.979,313.4692 L526.901,179.9092"
						id="Stroke-8"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M444.9795,313.4692 L531.1355,179.9092"
						id="Stroke-9"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M528.1978,187.5938 L528.1978,71.4198"
						id="Stroke-10"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M566.1978,106.9097 L490.1978,106.9097"
						id="Stroke-11"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M609.6099,366.9219 L523.2699,280.5809"
						id="Stroke-12"
						stroke="#000000"
						stroke-width="12"
					/>
					<path
						d="M446.0996,367.0806 L531.1116,280.0876"
						id="Stroke-13"
						stroke="#000000"
						stroke-width="12"
					/>
					<g id="Group-20">
						<path
							d="M547.9966,362.6045 C558.9306,373.5395 558.9306,391.2675 547.9966,402.2025 C537.0606,413.1365 519.3326,413.1365 508.3986,402.2025 C497.4636,391.2675 497.4636,373.5395 508.3986,362.6045 C519.3326,351.6695 537.0606,351.6695 547.9966,362.6045 L547.9966,362.6045 Z"
							id="Stroke-14"
							stroke="#000000"
							stroke-width="12"
						/>
						<mask id="mask-2" fill="white">
							<use xlink:href="#path-1" />
						</mask>
						<g id="Clip-17" />
						<polygon
							id="Stroke-16"
							stroke="#000000"
							stroke-width="12"
							mask="url(#mask-2)"
							points="483.578 644.193 572.817 644.193 572.817 495.77 483.578 495.77"
						/>
						<polygon
							id="Stroke-18"
							stroke="#000000"
							stroke-width="12"
							mask="url(#mask-2)"
							points="685.717 608.004 731.889 608.004 731.889 521.481 685.717 521.481"
						/>
						<polygon
							id="Stroke-19"
							stroke="#000000"
							stroke-width="12"
							mask="url(#mask-2)"
							points="320.955 610.004 367.128 610.004 367.128 523.481 320.955 523.481"
						/>
					</g>
				</g>
			</svg>
			<img v-if="tenant_id" class v-lazy-load :data-src="url+'/storage/tenants/'+tenant_id+'/logo.png?ts='+tenantts" />
		</nuxt-link>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		url() {
			return process.env.appUrl;
		},
		...mapGetters({
			isSidebarVisible: "main/isSidebarVisible",
			level: "auth/getlevel",
			user: "projects/getuser",
			isAuthenticated: "auth/isAuthenticated",
			tenant_id: "auth/tenant_id",
			tenantts: "main/tenantts"
		})
	},
	methods: {}
};
</script>
