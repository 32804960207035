<template>
	<div class="header-left" v-if="mobile">
		<b-button
			@click="back"
			type="is-primary"
			icon-left="chevron-left"
		></b-button>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "BackButton",
	data: () => ({ previous: false }),
	props: {},
	computed: {
		...mapGetters({
			mobile: "main/mobile",
			history1: "main/history",
		}),
	},
	methods: {
		back() {
			this.$store.dispatch("main/back", this.$route.name);
		},
	},
	mounted() {
		//console.log(this.history);
	},
};
</script>
