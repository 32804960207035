<template>
	<span class="socket"></span>
</template>

<script>
import { mapGetters } from "vuex";
import Branding from "~/components/Branding";
import { Plugins } from "@capacitor/core";
const { App, PushNotifications } = Plugins;
import axios from "axios";
import io from "socket.io-client";

export default {
	data() {
		return {
			isActive: true,
			prevclicktime: 0,
			showpopup: false,
			sockme: "",
		};
	},
	components: {
		Branding,
	},
	computed: {
		...mapGetters({
			isSidebarVisible: "main/isSidebarVisible",
			user_level: "auth/user_level",
			user: "main/getuser",
			token: "auth/token",
			socket_token: "auth/socket_token",
			user_id: "auth/user_id",
			device: "main/device",
		}),
	},
	sockets: {
		connect() {
			console.log("socket connected!");
			this.afterConnection();
		},
		customEmit(val) {
			console.log(
				'this method was fired by the socket server. eg: io.emit("customEmit", data)'
			);
		},
	},
	methods: {
		afterConnection() {
			// this.$socket.client is `socket.io-client` instance
			//if (this.user_id) {
			//this.$socket.client.emit("authenticate", { token: this.token });
			//this.$socket.client.emit("room", this.socket_token);
			//this.$socket.$subscribe("update_socket", (payload) => {
			//console.log(payload,payload.id);
			//	this.$store.dispatch(
			///		"main/fetchnewchannelcontributions",
			//		payload.id
			//	);
			//this.$store.dispatch("main/fetchstart", true);
			//	});
			//}
			//console.log("oke");
		},
	},
	mounted() {
		//alert(this.$socket);
		//this.sockme += JSON.stringify(this.$socket.connected);
		//console.log("joe");

		if (this.device.platform == "android") {
			this.backEvent = null;
			App.removeAllListeners();
			this.backEvent = App.addListener("backButton", (test) => {
				//console.log("back me up");
				let time = new Date().getTime();
				let duration = time - this.prevclicktime;

				//alert(time + " " + this.prevclicktime + " " + duration);

				if (this.$route.name == "index" && duration > 2000) {
					//alert("exit");
					this.showpopup = true;

					this.$buefy.toast.open({
						message: "Klik nogmaals terug om app te sluiten",
						type: "is-warning",
						duration: 2000,
					});
				} else if (this.$route.name == "index" && duration < 2000) {
					App.exitApp();
				} else {
					this.$store.dispatch("main/back", this.$route.name);
				}
				this.prevclicktime = time;
			});
		}

		if (
			(this.device.platform == "android" ||
				this.device.platform == "ios") &&
			this.user_level > -1
		) {
			PushNotifications.requestPermission().then((result) => {
				if (result.granted) {
					// Register with Apple / Google to receive push via APNS/FCM
					PushNotifications.register();

					PushNotifications.addListener("registration", (token) => {
						console.log(
							"Push registration success, token: " + token.value
						);
						//alert(token.value		);

						let json = {
							device_token: token.value,
							platform: this.device.platform,
						};

						//alert(json.device_token);
						axios
							.post("/users/setdevicetoken", json)
							.then(({ data }) => {})
							.catch((err) => {
								console.log(err);
								//alert(err);
							})
							.finally(() => {
								//this.isLoading = false;
								//alert("done");
							});
					});
					PushNotifications.addListener(
						"registrationError",
						(error) => {
							console.log(
								"Error on registration: " +
									JSON.stringify(error)
							);
						}
					);
					PushNotifications.addListener(
						"pushNotificationReceived",
						(notification) => {
							console.log(
								"Notification received: " +
									JSON.stringify(notification)
							);
						}
					);
					PushNotifications.addListener(
						"pushNotificationActionPerformed",
						(notification) => {
							console.log(
								"Error on registration: " +
									JSON.stringify(notification)
							);
						}
					);
				} else {
					// Show some error
					//alert("error");
				}
			});
		}

		//if (this.$socket.connected) {
		//	this.afterConnection();
		//}

		const socket = io(process.env.socketUrl);

		socket.on("connect", () => {
			socket.emit("authenticate", { token: this.token });
			socket.emit("room", this.socket_token);
		});

		socket.on("update_socket", (payload) => {

			//console.log(payload, payload.id);
			this.$store.dispatch(
				"main/fetchnewchannelcontributions",
				payload.id
			);
		});

	},
};
</script>
