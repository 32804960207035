
export const actions = {
  nuxtServerInit ({ commit }, { req }) {
    //const token = cookieFromRequest(req, 'token')
    let token=null;
    if (token) {
      commit('auth/SET_TOKEN', token)
    }

    //const locale = cookieFromRequest(req, 'locale')
    let locale=null;
    if (locale) {
      commit('lang/SET_LOCALE', { locale })
	}

	let api_url=process.env.apiUrl;
	commit('auth/SET_API_URL', api_url)

  },

  nuxtClientInit ({ commit,dispatch },{ route }) {
    //const token = Cookies.get('token')
    let token=null;
    if (token) {
      commit('auth/SET_TOKEN', token)
    }

    //const locale = Cookies.get('locale')
    let locale=null;
    if (locale) {
      commit('lang/SET_LOCALE', { locale })
	}

	let api_url=process.env.apiUrl;
	commit('auth/SET_API_URL', api_url);

	let media_url=process.env.mediaUrl;
	commit('auth/SET_MEDIA_URL', media_url)

	//dispatch('projects/init',{route:route})

  }
}
