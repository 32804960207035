<template>
	<div class="contribution_replies">
		<div v-for="(reply, key) in parent.children" :key="key" ref="reply">
			<contribution-reply :reply="reply" :parent="parent" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "contribution-replies",
	data: () => ({
		isLoading: false
	}),
	props: {
		parent: {}
	}
};
</script>
