<template>
	<div>
		<div
			class="password-helper"
			v-if="type == 'checker' && password && password.length > 0"
		>
			<div :class="{ 'is-passed': has_minimum_lenth }">
				<b-icon
					size="is-small"
					icon="check"
					class="check_icon"
					aria-hidden="true"
				></b-icon
				><b-icon
					size="is-small"
					icon="close"
					class="close_icon"
					aria-hidden="true"
				></b-icon>
				minimaal tien tekens lang
			</div>
			<div :class="{ 'is-passed': has_uppercase }">
				<b-icon
					size="is-small"
					icon="check"
					class="check_icon"
					aria-hidden="true"
				></b-icon
				><b-icon
					size="is-small"
					icon="close"
					class="close_icon"
					aria-hidden="true"
				></b-icon>
				minimaal één hoofdletter
			</div>
			<div :class="{ 'is-passed': has_lowercase }">
				<b-icon
					size="is-small"
					icon="check"
					class="check_icon"
					aria-hidden="true"
				></b-icon
				><b-icon
					size="is-small"
					icon="close"
					class="close_icon"
					aria-hidden="true"
				></b-icon>
				minimaal één kleine letter
			</div>
			<div :class="{ 'is-passed': has_number }">
				<b-icon
					size="is-small"
					icon="check"
					class="check_icon"
					aria-hidden="true"
				></b-icon
				><b-icon
					size="is-small"
					icon="close"
					class="close_icon"
					aria-hidden="true"
				></b-icon>
				minimaal één cijfer
			</div>
			<div :class="{ 'is-passed': has_special }">
				<b-icon
					size="is-small"
					icon="check"
					class="check_icon"
					aria-hidden="true"
				></b-icon
				><b-icon
					size="is-small"
					icon="close"
					class="close_icon"
					aria-hidden="true"
				></b-icon>
				minimaal één speciaal teken zoals @$!%*#?&amp;
			</div>
		</div>
		<div
			class="password-helper"
			v-if="
				password &&
				password_confirmation &&
				password_confirmation.length > 0 &&
				type == 'confirmation'
			"
		>
			<div :class="{ 'is-passed': passwords_match }">
				<b-icon
					size="is-small"
					icon="check"
					class="check_icon"
					aria-hidden="true"
				></b-icon
				><b-icon
					size="is-small"
					icon="close"
					class="close_icon"
					aria-hidden="true"
				></b-icon>
				wachtwoorden hetzelfde
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "PasswordHelper",
	data: () => ({}),
	props: {
		password: null,
		password_confirmation: null,
		type: null,
	},
	computed: {
		has_minimum_lenth() {
			if (this.password.length > 9) {
				return true;
			} else {
				return false;
			}
		},
		has_number() {
			if (/\d/.test(this.password)) {
				return true;
			} else {
				return false;
			}
		},
		has_lowercase() {
			if (/[a-z]/.test(this.password)) {
				return true;
			} else {
				return false;
			}
		},

		has_uppercase() {
			if (/[A-Z]/.test(this.password)) {
				return true;
			} else {
				return false;
			}
		},

		has_special() {
			//[@$!%*#?&€:;"|\/<>,.?{}^()-_~`]/', // must contain a special character

			if (
				/[!@#\$%\^\&*\)\(+=._\-?€;:"|\\\/<>,{}`~˜]/.test(this.password)
			) {
				return true;
			} else {
				return false;
			}
		},
		passwords_match() {
			if (this.password == this.password_confirmation) {
				return true;
			} else {
				return false;
			}
		},

		...mapGetters({
			mobile: "main/mobile",
			history1: "main/history",
		}),
	},
};
</script>
