const middleware = {}

middleware['atleastmember'] = require('../middleware/atleastmember.js')
middleware['atleastmember'] = middleware['atleastmember'].default || middleware['atleastmember']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['check-auth'] = require('../middleware/check-auth.js')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['historybuilder'] = require('../middleware/historybuilder.js')
middleware['historybuilder'] = middleware['historybuilder'].default || middleware['historybuilder']

middleware['setupshop'] = require('../middleware/setupshop.js')
middleware['setupshop'] = middleware['setupshop'].default || middleware['setupshop']

export default middleware
