<template>
	<div>
		<div
			class="contribution_reply"
			v-if="reply && reply.user && !edit_form"
		>
			<div class="reply_meta">
				<span class="author_name" @click="openUser(reply.user)">{{
					reply.user.displayname
				}}</span>
				<!--{{reply.user.displayname}}-->
				<span class="meta">{{
					reply.updated_at | moment("from", "now", true)
				}}</span>
			</div>
			<div class="reply_content" v-html="reply.content" />

			<b-button
				v-if="user_id == reply.user.id || user_level > 1"
				icon-left="pencil"
				@click="toggle_edit_form"
				type="is-text"
				size="is-small"
				>bewerk</b-button
			>

			<b-button
				icon-left="reply"
				@click="toggle_reply_form"
				type="is-text"
				size="is-small"
				>reageer</b-button
			>

			<contribution-reply-edit
				@close_reply_form="close_reply_form"
				v-if="reply_form"
				:parent="reply"
			/>

			<template v-if="reply.children">
				<contribution-replies :parent="reply" />
			</template>
		</div>
		<div class="contribution_reply" v-if="reply && reply.user && edit_form">
			<contribution-reply-edit
				@close_reply_form="close_reply_form"
				v-if="edit_form"
				:contribution="reply"
				:parent="parent"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "ContributionReply",
	data: () => ({
		reply_form: false,
		edit_form: false,
	}),
	props: {
		reply: {},
		parent: {},
	},
	computed: {
		...mapGetters({
			user_level: "auth/user_level",
			user_id: "auth/user_id",
			user: "projects/getuser",
		}),
	},

	methods: {
		toggle_reply_form() {
			this.reply_form = !this.reply_form;
		},
		toggle_edit_form() {
			this.edit_form = !this.edit_form;
		},
		close_reply_form() {
			this.reply_form = false;
			this.edit_form = false;
		},
		openUser(user) {
			$nuxt.$router.push("/users/" + user.id);
		},
	},
};
</script>
