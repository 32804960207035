import axios from 'axios'

export default function ({ store, redirect, route, from, app }) {
	// If nuxt generate, pass this middleware
	// eslint-disable-next-line no-useless-return
	// if (isServer && !req) return

	let token = app.$storage.getUniversal('token')
	axios.defaults.headers.common.Authorization = `Bearer ${token}`

	let tenant_id = app.$storage.getUniversal('tenant_id')

	//the middleware is executed before the state, I guess? so this is not working
	//let tenant_id = store.getters['auth/tenant_id'];

	if (tenant_id) {
		axios.defaults.headers.common.tenant = tenant_id
		store.commit('auth/set_tenant_id', tenant_id);
	}

	axios.defaults.headers.common.appversion = '2.0.0'

	store.commit('auth/GET_USER')
}
